@use '../../../../../../../Assets/Styles/variables';
.business-contact {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.8rem;
}

.customer-profile {
  background-color: variables.$table-row-hover;
  border-radius: 50%;
  color: variables.$primary-color;
  padding: 1.5rem 1.3rem;
  font-weight: 500;
  font-size: 1.125rem;
}

.customer-profile-outer {
  display: flex;
  justify-content: center;
  align-items: center;
}