@use "../../Assets/Styles/variables";
.details-box {

    h2,
    h3 {
        span {
            font-weight: normal;
        }

        color: variables.$primary-neutral-text-color;
        ;
    }

    .table-heading {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    .table-content {
        padding: 0;
    }
}

.has-serial-number{
    div {
        &[role=row] {
    
            >div:first-child{
                width: auto !important;
                min-width: 5vw;
                flex: 0 0 !important;
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
            >div:last-child {
                width: auto !important;
                min-width: 13vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 2rem;
            }
    
            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }    
}

div {
    &[role=row] {

        >div:first-child{
            width: auto !important;
            min-width: 13vw;
            flex: 0 0 !important;
            padding-left: 2rem;
            padding-right: 2rem;
        }
        >div:last-child {
            width: auto !important;
            min-width: 13vw;
            flex: 0 0 !important;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;
        }
    }
}


div {
    &[role=cell] {
        div {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;

            div {
                color: variables.$primary-neutral-text-color;
                font-size: 0.875rem;
            }
        }
    }
}