.filter-column-height {
    min-height: 60vh !important;
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .filter-column-height {
        min-height: 20vh !important;
        min-width: 30vw;
    }
}

//ipad-pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .ipad-pro-filterbox {
        max-height: calc(32vh - 100px) !important;
    }
}