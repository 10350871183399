@use '../../../../../../Assets/Styles/variables';
.infinity-loader-wrapper {
    height: 3rem;
}


// ipad mini and air
@media only screen and (max-width: 992px) and (min-width: 768px) {
    .subscription-renewal-widget {
        >div:first-child {
            max-height: 65vh;

            >div:first-child {
                min-height: 763px;
            }
        }
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .subscription-renewal-widget {
        >div:first-child {
            max-height: 65vh;

            >div:first-child {
                min-height: 763px;
            }
        }
    }
}

.subscription-renewal-widget {
  width: 100%;
  min-height: 300px;
  position: relative;

  .rdt_Table {
    background: variables.$text-color-light;
  }

  .rdt_TableRow {
    border-bottom: 1px solid #E5E7EB;
  }

  .csp-vendor-id {
    font-size: 0.75rem;
    color: variables.$secondary-border-color;
    text-transform: uppercase;
  }

  .dashboard-change-status {
    width: 24px;
    height: 24px;
    cursor: pointer;
    display: inline-block;
    background-size: contain;
  }
}

.infinity-loader-wrapper {
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 992px) {
  .subscription-renewal-widget {
    .rdt_Table {
      min-width: 900px;
    }
  }
}