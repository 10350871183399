@use '../../../../../../Assets/Styles/variables';
.accordion-header-wrapper {
    display: flex;
    cursor: pointer;



    .accordion-number {
        border: 1px solid variables.$primary-color;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        line-height: 1.5rem;
        border-radius: 1.5rem;
        font-size: 0.875rem;
        color: variables.$stepper-number-background;
    }

    .accordion-name {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 500;
    }

    label {
        span {
            display: block;
        }
    }
}

.accordion-content {
    padding-left: 2rem;
}

.success-panel {
    padding: 2rem 0;
    text-align: center;
}

.transition-domain-checks {
    display: block;

    .round-number {
        background: variables.$primary-border-color;
        color: variables.$primary-neutral-text-color;
        font-size: 0.875rem;
        font-weight: 500;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 1.25rem;
        text-align: center;
        line-height: 1.25rem;
        margin-right: 0.5rem;
    }
}