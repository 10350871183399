@use '../../../../../Assets/Styles/variables';
.gcp-service-management-logo {
    height: 1.7rem;
    width: auto;
}

.user-management-table {
    padding-left: 1rem;
    padding-right: 1rem;
    max-width: 100%;
}

.gcp-color-underline {
    color: variables.$primary-color;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 400;
}

div {
    &[role=columnheader] {
        >div {
            color: variables.$secondary-neutral-color;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        &:hover {
            opacity: 1;

            >div {
                color: variables.$secondary-neutral-color;
                font-size: 0.875rem;
                font-weight: 500;


            }
        }
    }
}

.ibm-service-table {
    div {
        &[role=cell] {
            div {
                font-size: 0.875rem;
                line-height: 1.125rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;

                div {
                    color: variables.$primary-neutral-text-color;
                    font-size: 0.875rem;
                }
            }
        }
    }
}

.password-ibm {
    width: 7vw;
}


.ibm-service-table {

    >.details-box {
        margin-bottom: 2rem !important;
    }

    div {
        &[role=row] {

            >div:first-child {
                width: auto !important;
                min-width: 30vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 25vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.loading-subscriptions {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-data-ibm-error-line {
    min-height: 10vh;
    background: variables.$text-color-light;
    border-radius: 0.625rem;
    display: flex;
    font-weight: bold;
    flex-direction: column;
}

.no-data-ibm {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    flex-direction: column;
}

.search-input-box-gcp {
    margin-right: 0.75rem;
    max-width: 25rem !important;
}


.status-gcp {
    text-align: center;
}

@media screen and (max-width: 993px) {
    .user-history-table {

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 10rem;
            word-break: initial;
        }
    }

    .custom-modal-user-content {
        max-width: 500px !important;
        min-width: 300px !important;
        width: auto;
    }
}

@media screen and (max-width: 767px) {

    .custom-modal-user-content {
        max-width: 90% !important;
        min-width: 200px !important;
        width: auto;
    }

    .user-management-table {
        padding: 0px !important;
        overflow: hidden;
    }

    .user-history-table {

        div[role=table]>div[role=rowgroup]> {
            div[role=row]>div {
                min-width: 12rem;
            }
        }

        div[role=columnheader]>div {
            display: flex;

            .sort-icon-desc,
            .sort-icon-asc {
                background-size: 100% !important;
            }
        }

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 10rem;
            word-break: initial;
        }
    }
}

.user-message {
    white-space: normal;
    word-break: break-all;
    max-height: 10vh;
    overflow-y: auto;
}

.ticket-table-additional-information-popup-loading {
    min-height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.service-management-heading {
    font-size: 1.25rem !important;
    font-weight: 500;
}

.input-box-gcp {
    max-width: 25rem !important;
}