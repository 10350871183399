@use '../../../../../../../Assets/Styles/variables';
.no-data-cloud-invoice {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.productlisting-main-table {
    border-top: 2px solid variables.$primary-border-color;

    td {
        vertical-align: middle;
    }
}

.billing-term {
    font-weight: 500 !important;
}

.csp-tooltip {
    z-index: 999999;
}

// .big-width{
//     max-width: 4rem;
// }
// .small-width{
//     max-width: 3rem !important;
// }

.edit-quantity-width {
    min-width: 80vw !important;
    // min-height: 50vh !important;
    max-height: 70vh !important;
    overflow-y: auto;
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
}



.show-qty-btn {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: calc(100% - 1.5rem);
    height: 100%;
    cursor: pointer;
    margin: 0;
    position: relative;
    display: flex;
    line-height: 1.25rem !important;
    padding: 0;
    font-size: 1.125rem !important;
    color: variables.$secondary-neutral-color;
    text-align: center;
}

.btn-wrapper {
    width: 1.5rem;
    height: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-left: 1px solid variables.$primary-border-color;
    border-right: 1px solid variables.$primary-border-color;
    align-items: center;
    border-radius: 0 6px 6px 0;
    margin-left: -1.5rem;
    border: 1px solid variables.$primary-border-color;
    overflow: hidden;
    background: variables.$text-color-light;
}

.qty-wrapper {
    min-width: 8rem;
    display: inline-flex;
    justify-content: start;
}

.show-qty-btn-wrapper {
    border: 1px solid variables.$primary-border-color;
    display: inline-flex;
    border-radius: 6px;
    overflow: hidden;
    background: variables.$text-color-light;
    width: 5rem;
    align-items: center;

    &.disabled {
        border: 1px solid #e0e0e0;
        background: variables.$button-disabled-background;
        cursor: not-allowed;

        label {
            background: variables.$button-disabled-background;
            cursor: not-allowed;
        }

        .btn-wrapper {
            background: variables.$button-disabled-background;
            border-left: 1px solid #cdcdcd !important;
        }
    }

    label {
        border-right: 0px !important;
        border: 0px;
        margin-right: 24px;
        flex-grow: 2;
        width: calc(100% - 24px);
        text-align: center;
        font-size: 0.875rem !important;
        background: variables.$text-color-light;

        &:focus {
            outline: none;
        }
    }

    .btn-wrapper {
        width: 1.5rem;
        height: 2.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        border: 0px !important;
        border-left: 1px solid variables.$primary-border-color !important;
        align-items: center;
        border-radius: 0 6px 6px 0;
        margin-left: -1.5rem;
        border: 1px solid variables.$primary-border-color;
        overflow: hidden;
        background: variables.$text-color-light;

        .plus {
            border-bottom: 1px solid variables.$primary-border-color !important;

            &.disabled {
                border-radius: 0 6px 0 0;
            }
        }

        .minus {

            span {
                width: 0.5rem;
                height: 2px;
                background: variables.$secondary-neutral-color;
            }

            &.disabled {
                border-radius: 0 0px 6px 0;

                span {
                    width: 0.5rem;
                    height: 2px;
                    background: variables.$secondary-neutral-color;
                }
            }
        }
    }

    button {
        outline: none;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.25rem;
        cursor: pointer;
        margin: 0;
        position: relative;
        display: flex;
        line-height: initial;
        padding: 0;
        font-size: 1.125rem;
        color: variables.$secondary-neutral-color;


        &.disabled {
            background-color: variables.$button-disabled-background;
            cursor: not-allowed;
        }
    }
}

.selected-row {
    td {
        background: variables.$table-row-hover;
    }
}

.product-plan-top-right-card {
    padding-top: 0.5rem;

    label {
        font-size: 0.875rem;
        color: variables.$primary-neutral-text-color;
    }
}

.csp-purchase-addon-count-row {
    background: variables.$text-color-light;
    flex-grow: 1;
    margin-bottom: 0;

    >div {
        border: 1px solid #3599DA;
        border-radius: 0 0 0.75rem 0.75rem;
        background: variables.$text-color-light;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }
}

.csp-purchase-addon-count-row-inner {
    padding: 1rem 1rem !important;
}

.csp-change-status-core-table {
    thead tr th:first-child {
        max-width: 12rem !important;
        // padding: 0;
    }

    tbody tr td:first-child {
        max-width: 12rem !important;
        // padding: 0;
    }

    tbody tr {
        vertical-align: middle;
    }

    .qty-wrapper-cell {
        min-width: 10rem;
    }

}

.csp-popup-table {
    max-height: 40vh;
}

.csp-popup-table-total {
    max-height: 15rem;

    td {
        padding-right: 1rem;
    }
}

.delete-column {
    width: 6rem !important;
}


.total-block-right {
    width: 6rem;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.total-block-row {
    border-top: 1px solid variables.$primary-border-color;
    display: inline-block;
    min-width: 14rem;
    padding-top: 0.5rem;
    margin-top: 0.25rem;
}
.gws-edit-quantity-col-rrpExclusiveTax{
    min-width: 12vw !important;
}
.gws-edit-quantity-col-plan{
    min-width: 8vw !important;
}
.gws-edit-quantity-col-rrp{
    min-width: 8vw !important;
}
.gws-edit-quantity-col-category{
    min-width: 8vw !important;
}

.gws-edit-quantity-col-price{
    min-width: 8vw !important;
}
.gws-edit-quantity-sub-total-col{
min-width: 8vw !important;
}

.sticky-bottom {
    position: fixed;
    max-width: 77.5%;
    bottom: 0;
    z-index: 1;
    background-color: variables.$text-color-light;
    /* Adjust the background color as needed */
}

@media screen and (max-width: 767px) {
    .product-plan-top-right-card {
        padding-top: 1rem;
    }

    .mobile-display {
        display: none;
    }

    .big-width {
        min-width: 35vw !important;
    }

    .small-width {
        min-width: 35vw !important;
    }

    .csp-change-status-core-table thead tr th:last-child {
        display: none;
    }
}


@media screen and (min-width: 1023px) and (max-width: 1025px) {
    table.csp-change-status-core-table th {
        min-width: 11rem !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px){
    .gws-edit-quantity-col-rrpExclusiveTax{
        min-width: 20vw !important;
    }
    
}