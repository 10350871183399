.help-table-additional-information-popup-loading {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        font-size: 1.2rem;
        font-weight: 500;
    }
}
.help-table-additional-information-popup-data{
    min-height: 20vh;
}

.training-widget-content-main-container{
    max-height: 50vh;
    overflow-y: auto;
}
