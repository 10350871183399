@use '../../../../../../Assets/Styles/variables';
.no-data-cloud-invoice {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.productlisting-main-table {
    border-top: 2px solid variables.$primary-border-color;
}

.big-width {
    max-width: 4rem;
}

.small-width {
    max-width: 2rem;
}

.csp-tooltip {
    z-index: 999999;
}

.csp-popup-table-review-provision {
    height: 100%;
}

.csp-change-status-core-table {

    thead tr th:first-child {
        min-width: 12rem !important;
        // padding: 0;
    }

    thead tr th:last-child {
        padding-right: 4rem;
        // padding: 0;
    }

    tbody tr td:first-child {
        max-width: 12rem !important;
        // padding: 0;
    }

    tbody tr {
        vertical-align: middle;
    }

    .qty-wrapper-cell {
        min-width: 10rem;
    }

    &.csp-change-status-core-table-step1 {
        thead tr th:last-child {
            padding-right: 1rem;
            // padding: 0;
        }
    }

}

.no-data-cloud-summary {
    min-height: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.qty-wrapper {
    min-width: 8rem;
    display: inline-flex;
    justify-content: start;
}

.csp-change-status-core-table-read-only {

    thead tr th:first-child {
        max-width: 12rem !important;
        // padding: 0;
    }

    thead tr th:last-child {
        padding-right: 1rem;
        // padding: 0;
    }

    tbody tr td:first-child {
        max-width: 12rem !important;
        // padding: 0;
    }

    tbody tr {
        vertical-align: middle;
    }

    .qty-wrapper-cell {
        min-width: 10rem;
    }

}

.csp-change-status-core-table-order-confirmation {
    thead tr th:first-child {
        max-width: 12rem !important;
        // padding: 0;
    }

    thead tr th {
        span {
            font-weight: 500;
            font-size: 0.875rem;
            text-transform: capitalize;
            color: rgb(106, 106, 106) !important;
        }
    }

    thead tr th {
        padding-left: 4rem;
        // padding: 0;
    }

    tbody tr td:first-child {
        max-width: 12rem !important;
        // padding: 0;
    }

    tbody tr {
        vertical-align: middle;
    }

    .qty-wrapper-cell {
        min-width: 10rem;
    }

}

.table-with-scroll {
    // max-height: 20rem;
    // overflow: auto;
}

.inner-table {
    width: auto !important;


    .col {
        &:first-child {
            width: 10rem;
        }
    }


    // tr {
    //     border-bottom: 0 !important;

    //     td {
    //         border-bottom: 0 !important;
    //         padding: 0 0 0.5rem 0rem !important;

    //         &:first-child {
    //             width: 100% !important;
    //         }

    //         span {}

    //     }

    //     &:last-child {
    //         td {
    //             padding-top: 0rem !important;

    //             span {
    //                 border-top: 1px solid variables.$primary-border-color;
    //                 min-width: 5rem;
    //                 display: inline-block;
    //                 padding-top: 0.5rem;

    //             }

    //         }
    //     }
    // }
}

.csp-popup-table {
    max-height: 40vh;
}

.csp-popup-table-total {
    max-height: 15rem;

    td {
        padding-right: 1rem;
    }
}

.delete-column {
    width: 6rem !important;
}


.total-block-right {
    width: 6rem;
    display: inline-block;
    margin-bottom: 0.5rem;
}

.total-block-row {
    border-top: 1px solid variables.$primary-border-color;
    display: inline-block;
    min-width: 14rem;
    padding-top: 0.5rem;
    margin-top: 0.25rem;
}

.GCP-plan-selected {
    padding: 0 !important;
}

.csp-purchase-addon-count-row {
    background: variables.$text-color-light;
    flex-grow: 1;
    margin-bottom: 0;
    // position: sticky;

    >div {
        border: 1px solid #3599DA;
        border-radius: 0 0 0.75rem 0.75rem;
        background: variables.$text-color-light;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }
}

.csp-purchase-addon-count-row-inner {
    padding: 1rem 1rem !important;
}

.empty-selected-data {
    height: 4.5rem !important;
}

.edit-quantity-width {
    min-width: 90vw !important;
    // min-height: 50vh !important;
    max-height: 80vh !important;
    overflow-y: auto;
    // display: flex;
    // justify-content: center;
    // flex-direction: column;
}


.show-qty-btn {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: calc(100% - 1.5rem);
    height: 100%;
    cursor: pointer;
    margin: 0;
    position: relative;
    display: flex;
    line-height: 1.25rem !important;
    padding: 0;
    font-size: 1.125rem !important;
    color: variables.$secondary-neutral-color;
    text-align: center;
}

.btn-wrapper {
    width: 1.5rem;
    height: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-left: 1px solid variables.$primary-border-color;
    border-right: 1px solid variables.$primary-border-color;
    align-items: center;
    border-radius: 0 6px 6px 0;
    margin-left: -1.5rem;
    border: 1px solid variables.$primary-border-color;
    overflow: hidden;
    background: variables.$text-color-light;
}

.show-qty-btn-wrapper {
    border: 1px solid variables.$primary-border-color;
    display: inline-flex;
    border-radius: 6px;
    overflow: hidden;
    background: variables.$text-color-light;
    width: 5rem;
    align-items: center;

    label {
        border-right: 0px !important;
        border: 0px;
        margin-right: 1.5rem;
        flex-grow: 2;
        width: calc(100% - 1.5rem);
        text-align: center;
        font-size: 0.875rem !important;
        background: variables.$text-color-light;

        &:focus {
            outline: none;
        }
    }

    .btn-wrapper {
        width: 1.5rem;
        height: 2.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        border: 0px !important;
        border-left: 1px solid variables.$primary-border-color !important;
        align-items: center;
        border-radius: 0 6px 6px 0;
        margin-left: -1.5rem;
        border: 1px solid variables.$primary-border-color;
        overflow: hidden;
        background: variables.$text-color-light;

        .plus {
            border-bottom: 1px solid variables.$primary-border-color !important;

            &.disabled {
                border-radius: 0 6px 0 0;
            }
        }

        .minus {

            span {
                width: 0.5rem;
                height: 2px;
                background: variables.$secondary-neutral-color;
            }

            &.disabled {
                border-radius: 0 0px 6px 0;

                span {
                    width: 0.5rem;
                    height: 2px;
                    background: variables.$table-column-background-secondary;
                }
            }
        }
    }

    button {
        outline: none;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.25rem;
        cursor: pointer;
        margin: 0;
        position: relative;
        display: flex;
        line-height: initial;
        padding: 0;
        font-size: 1.125rem;
        color: variables.$secondary-neutral-color;


        &.disabled {
            background-color: variables.$button-disabled-background;
            cursor: not-allowed;
        }
    }
}

.selected-row {
    td {
        background: variables.$table-row-hover;
    }
}


tbody {
    .smb-error {

        position: relative;

        td {
            padding-bottom: 2rem !important;
        }

        &:after {
            border-left: 3px solid variables.$primary-danger;
            border-radius: 4px 0 0 4px;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 1rem;
        }

        .smb-error-message * {
            position: absolute;
            padding: 0.5rem 0;
            color: variables.$primary-danger;
            width: auto;
            bottom: 0.10rem;
        }

    }
}

.csp-popup-table-step1 {
    max-height: 50vh !important;
}

.commitment-term-width {
    width: 10rem !important;
} 

@media screen and (max-width: 1400px) and (min-width: 993px) {

    .csp-popup-table {
        max-height: 20vh;
    }

    .edit-quantity-width {
        min-width: 90vw !important;
        // min-height: 50vh !important;
        max-height: 80vh !important;
        overflow-y: auto;
        // display: flex;
        // justify-content: center;
        // flex-direction: column;
    }
}

.sticky-bottom {
    position: fixed;
    max-width: 77.5%;
    bottom: 0;
    z-index: 1;
    background-color: variables.$text-color-light;
}

@media screen and (max-width: 767px) {
    .product-plan-top-right-card {
        padding-top: 1rem;
    }

    .mobile-display {
        display: none;
    }

    .big-width {
        min-width: 35vw !important;
    }

    .small-width {
        min-width: 35vw !important;
    }

    .csp-change-status-core-table thead tr th:last-child {
        display: none;
    }
}

@media screen and (min-width: 1023px) and (max-width: 1025px) {
    table.csp-change-status-core-table th {
        min-width: 11rem !important;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .product-plan-ipad-pro {
        align-items: baseline;
    }
}