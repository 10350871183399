@use "../variables";
@use "../mixins.scss";


.main-page {
    background: variables.$background-secondary-color;
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: variables.$secondary-border-color !important;
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: variables.$secondary-border-color !important;
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: variables.$secondary-border-color !important;
}

.main-page,
.popup-content {
    .form-group {
        margin-bottom: 1.85rem;
    }

    .form-control {
        height: 2.5rem;
        border: 1px solid variables.$primary-border-color;
        border-radius: 6px;
        color: variables.$secondary-neutral-color;
        font-size: 0.875rem;

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: variables.$secondary-border-color;
            opacity: 1;
            /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: variables.$secondary-border-color;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            color: variables.$secondary-border-color;
        }

        &:focus,
        &:active {
            border: 1px solid variables.$primary-color;
            box-shadow: none;
            color: variables.$primary-neutral-text-color !important;

            +.btn-wrapper {
                border-left: 1px solid variables.$primary-color;
            }
        }

        &:disabled {
            border: 1px solid variables.$primary-border-color;
            background-color: variables.$primary-border-color;
            opacity: 0.5;
            color:  variables.$secondary-neutral-color !important;
        }
    }

    textarea.form-control {
        height: auto;
    }

    .form-label {
        font-size: 0.875rem;
        line-height: 1.026rem;
        color: variables.$primary-neutral-text-color;
        opacity: 0.8;
    }

    .form-text {
        font-size: 1rem;
    }



    .btn {
        height: 2.5rem;
        border-radius: 3rem;
        line-height: 1;
        font-size: 0.875rem;
        font-weight: 500;
        min-width: 100px;
        padding: 8px 10px;

        &.btn-primary {
            background: variables.$primary-color;
            border-color: variables.$primary-color;

            &:hover,
            &:focus {
                background: variables.$secondary-color;
                border-color: variables.$secondary-color;
            }
        }

        .btn &:disabled {
            background: #bbbbbb;
            border-color: #bbbbbb;
            color: #646464;
        }


    }

    .form-select {
        padding: 8px 15px;
        border: 1px solid #ced4da;
        font-size: 0.825rem;
        color: variables.$secondary-neutral-color;

        option {
            font-size: 0.825rem;
            color: variables.$primary-neutral-text-color;
            line-height: 1.25rem;
        }

        &:focus {
            box-shadow: none;
        }

        &.form-select-custom {
            border-radius: 2.5rem;
            background-color: variables.$table-row-hover;
            border: 1px solid variables.$table-row-hover;

            &:focus {
                box-shadow: none;
                border: 1px solid variables.$table-row-hover;
            }
        }
    }


    .btn-border-light {
        background: variables.$text-color-light;
        border: 1px solid variables.$primary-color;
        color: variables.$primary-color;

        &:hover {
            background-color: variables.$table-row-hover;
        }

        &:focus {
            background-color: variables.$table-row-hover;
        }
    }
}

.btn {

    &:disabled,
    &.btn-disabled {
        background-color: variables.$primary-border-color;
        border-color: variables.$primary-border-color;
        color: variables.$secondary-border-color;
    }
}

.form-check-input:checked {
    background-color: variables.$primary-color;
    border-color: variables.$primary-color;
    box-shadow: none;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-check-input {
    border: variables.$primary-border-color;
    border-width: 1px;
    border-style: solid;
}

.form-check-lg {
    line-height: 1;
    margin-bottom: 0;
    display: flex;
    align-items: center;

    .form-check-input {
        width: 1rem;
        height: 1rem;
        margin-right: 0.5rem;
    }

    label {
        width: calc(100% - 2rem);
    }
}

.form-switch {
    .form-check-input {
        background: variables.$secondary-border-color;
        border: 0;
        position: relative;
        background-image: none !important;
        width: 2rem;
        height: 1.125rem;

        +label {
            color: variables.$secondary-neutral-color;
        }

        &:focus {
            box-shadow: none !important;
        }

        &:checked {
            background-image: none;
            background: variables.$primary-color;

            +label {
                color: variables.$secondary-neutral-color;
            }

            &:before {
                position: absolute;
                left: 1rem;
                transition: all .15s ease-in-out;
            }
        }

        &:before {
            position: absolute;
            left: 3px;
            top: 3px;
            width: 0.75rem;
            height: 0.75rem;
            border-radius: 0.75rem;
            background: variables.$text-color-light;
            content: "";
            transition: all .15s ease-in-out;
        }
    }
}




@media screen and (max-width: 1370px) {
    .main-page {
        .form-group {
            margin-bottom: 1.25rem;
        }
    }
}

// @media screen and (max-width: 767px) {
//     .btn-wrapper-outer {
//         .btn {
//             width: 100% !important;
//             margin: 0px 0px 1rem 0px !important;
//         }
//     }
// }

/* CSS for hiding arrows in Mozilla Firefox */
@-moz-document url-prefix() {
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

.expand-collapse-button{
    width: 8rem;
}

*:not(.form-switch) > .form-check-input:checked:disabled {
    opacity: 1;
    background-color: variables.$primary-border-color;
    border: variables.$primary-border-color;
    background-size: 0.75rem auto;
    background-image: url(../../Images/Icons/tick-grey.svg);
}

*:not(.form-switch) > .form-check-input:disabled {
    opacity: 1;
    background-color: variables.$text-color-light;
    border: 1px solid variables.$primary-border-color;
    background-size: 0.75rem auto;
}