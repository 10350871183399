@use "../../../../../../../../Assets/Styles/variables";
.accordion-upgradeGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.loading-plans {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.gws-tooltip{
    z-index: 999999 !important;
}

.gws-edit-quantity-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            div[data-column-id = "Reseller Price (Ex GST)"] {
                width: auto !important;
                min-width: 13rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 12vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            >div[data-column-id = "Sub - Total Price"] {
                width: auto !important;
                min-width: 10rem;
            }
            >div[data-column-id = "Current Quantity"] {
                width: auto !important;
                min-width: 9rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.gws-edit-quantity-table-order-confirmation {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 18vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div[data-column-id = "SKU ID"] {
                width: auto !important;
                min-width: 9rem;
            }
            
            >div:last-child {
                width: auto !important;
                min-width: 10vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            >div[data-column-id = "Sub - Total Price"] {
                width: auto !important;
                min-width: 12rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.gws-subsription-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 25vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.edit-quantity-width {
    width: 90vw !important;
    max-height: 50vh !important;
    overflow-y: auto;
}

//ipad pro screen size
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .gws-subsription-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 20vw;
                }
            }
        }
    }
}