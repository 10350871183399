@use '../../../../../../../../../Assets/Styles/variables';

.upload-block {
    p {
        padding-top: 0.25rem;
    }
}

.number-block {
    width: 3rem;
    flex-grow: 1;
    max-width: 3rem;

    span {
        border: 1px solid variables.$primary-color;
        color: variables.$primary-color;
        width: 2rem;
        display: inline-block;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        border-radius: 2rem;
    }
}

.profile-table {
    padding-left: 2rem;
    padding-right: 2rem;
    max-width: 100%;
}

.no-data-found {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-data-cloud-invoice {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 767px) {

    .profile-table {
        max-width: 100%;
    }

    .bulk-mapping-tab-pane>.active {
        display: block;
        overflow: hidden;
        width: 80vw;
    }

    .bulk-mapping-table {
        max-width: 100% !important;

        >div {
            min-height: 300px;
        }

        div {
            &[role=row] {

                >div:first-child {
                    width: auto !important;
                    min-width: 30vw;
                    flex: 0 0 !important;
                    padding-left: 0rem;
                    padding-right: 0rem;
                }

                >div {
                    width: auto !important;
                    min-width: 30vw;
                    flex: 0 0 !important;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }

                >div:last-child {
                    width: auto !important;
                    min-width: 30vw;
                    flex: 0 0 !important;
                    padding-left: 2rem;
                    padding-right: 2rem;
                }

                &:hover {
                    opacity: 1;
                    background: variables.$table-hover-color !important;
                }
            }
        }
    }
}