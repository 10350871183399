@use '../../../Assets/Styles/variables';
.profile-table {
    padding-left: 2rem;
    padding-right: 2rem;
}

.my-profile-last-section {
    border-top: 2px solid #e7e7e7;
}

.maximum-add {
    color: variables.$secondary-neutral-color;
}

.member-options {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 20px;
    // margin-top: 10px;
    // margin-top: -12px;
    margin-top: 5px;



    label {
        align-items: center;
        background: hsla(0, 0%, 87%, .45);
        border-radius: 6px;
        color: variables.$secondary-neutral-color;
        display: flex;
        margin: 0 5px 5px 0;
        padding: 8px 0 8px 10px;
        width: calc(100% - 5px);
    }

    span {
        color: variables.$secondary-neutral-color;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
    }
}

.no-data-cloud-invoice {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.btn-border-light {
  border: 1px solid #dee2e6;
}

.text-white {
  color: #ffffff;
}

 // Content area styles
.content {
.subscriptionsRenewalConfiguration {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
    
    .notification-group {
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
      
      span {
        font-size: 14px;
        white-space: nowrap;
      }
    }
    
    // Dropdown widths for desktop and tablet
    .border-width-subscriptions {
      &-date {
        width: 100px;
        min-width: 100px;
      }
      
      &-month {
        width: 120px;
        min-width: 120px;
      }
    }
  }
}

// Dropdown widths
.border-width-subscriptions {
    &-date {
      width: 8%;
    }

    &-month {
      width: 11%;
    }
}

.email-list {
  .email-item {
    padding: 12px 16px;
    align-items: flex-start;

    .email-content {
      flex: 1;
      min-width: 0;
    }

    .person-name {
      font-weight: 500;
      color: #333;
      margin-bottom: 4px;
    }

    .email-address {
      color: #666;
      font-size: 0.9rem;
      word-break: break-all;
    }

    .organization {
      flex: 1;
      min-width: 0;

      .organization-label {
        font-weight: 500;
        color: #333;
        margin-bottom: 4px;
      }
    }

    .remove-button {
      padding-top: 4px;
      cursor: pointer;
      color: #666;

      &:hover {
        color: #333;
      }
    }
  }
}

// Media queries
@media (max-width: 768px) {
    .border-width-subscriptions {
      &-date,
      &-month {
        width: 63% !important;
      }
    }

    .content {
    .subscriptionsRenewalConfiguration {
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      
      .notification-group {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        
        span {
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }
    }
}


.border-header {
    border-bottom: 1px solid #E5E7EB;
  }

  // Dropdown widths
  .border-width-config {
    &-date {
      width: 8%;
    }

    &-month {
      width: 11%;
    }
  }

  // Content area styles
  .content {
  .notificationSettings {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
    
    .notification-group {
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
      
      span {
        font-size: 14px;
        white-space: nowrap;
      }
    }
    
    // Dropdown widths for desktop and tablet
    .border-width-config {
      &-date {
        width: 100px;
        min-width: 100px;

        .dropdown-menu {
          max-height: 60vh;
        }
      }
      
      &-month {
        width: 120px;
        min-width: 120px;

        .dropdown-menu {
              max-height: 40vh; 
            }


      }
    }
  }

    .reportNote {
      font-size: 14px;
      margin-bottom: 24px;

      span {
        font-size: 14px;
        font-weight: 700;
      }
    }

    @media screen and (min-width: 821px) {
    .notes-confirmation-configure-notification {
      transform: translateY(22%);
    }
  }

    .note {
      font-size: 12px;

      span {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  // Media queries
  @media (max-width: 767px) {
    .border-width-config {
      &-date,
      &-month {
        width: 100% !important;
      }
    }

    .downloadreport-header {
      padding: 0;
    }

    .content {
    .notificationSettings {
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      
      .notification-group {
        display: flex;
        align-items: center;
        gap: 8px;
        flex: 1;
        
        span {
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }

      .reportNote,
      .note {
        text-align: left;
        font-size: 12px;

        span {
        font-size: 12px;
        font-weight: 700;
      }
      }

      .note {
        margin: 0 0 16px 11px;
        width: 100%;
      }
    }

    .configure-notification-button {
      width: 100%;
      margin: 4px 0;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 820px) {

    .downloadreport-header {
      padding: 0;
      text-align: right;
    }
}


   .email-member-options {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 0;
    margin-top: 5px;

    label {
      align-items: center;
      background: hsla(0, 0%, 87%, 0.45);
      border-radius: 6px;
      color: #6a6a6a;
      display: flex;
      margin: 0 5px 5px 0;
      padding: 8px 0 8px 10px;
      width: calc(100% - 5px);

      span {
        color: #6a6a6a;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
      }

      .close-icon-popup-sm {
        margin-left: auto;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }