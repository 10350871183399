@use '../../../../../Assets/Styles/variables';
.accordion-number {
    border: 1px solid variables.$primary-color;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    line-height: 1.5rem;
    border-radius: 1.5rem;
    font-size: 0.875rem;
    color: variables.$stepper-number-background;
}

.numbers { 
    width: 1.3rem; 
    line-height: 1.2rem; 
    border-radius: 50%; 
    text-align: center; 
    border: 1px variables.$primary-color solid;
    background-color: transparent; 
    color: variables.$primary-color; 
    font-weight: 500;
    font-size: 0.75rem;
} 