@use '../../../../../../../../Assets/Styles/variables';
.gws-subsription-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 25vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.loading-plans {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.accordion-changePaymentPlanGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.gws-changePaymentPlanGWS-core-step2 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 16vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 13vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }
        }
    }
}

.gws-downgrade-core-step2 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div[role=headCell]:first-child {
                background-color: variables.$table-column-background-secondary;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.accordion-downgradeGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.gws-downgrade-core-step3 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 8vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.css-vnkopk-MuiStepLabel-iconContainer {
    color: variables.$primary-border-color !important;
}

.gws-trial-to-paid-core-table {

    thead tr {
        border-bottom: 0px !important;

        th {
            font-size: 0.875rem !important;
            font-weight: 500 !important;

        }
    }

    tbody tr {
        td {
            font-weight: 400 !important;
            word-break: normal !important;
            font-size: 0.875rem !important;
        }
    }

}

// for billing term(commitment term)
.gws-tooltip {
    z-index: 999999 !important;
}

.no-data-cloud-invoice {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.trial-to-paid-gws-confirmation-line strong {
    word-break: normal !important;
}

.highlight-renewal-plan-text-gws {
    font-weight: 500 !important;
}

.loading-plans {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gws-edit-quantity-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.modal-width {
    width: 90vw !important;
    max-height: 80vh !important;
    overflow-y: auto;
}

//ipad pro screen size
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .gws-subsription-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 20vw;
                }
            }
        }
    }
    .gws-changePaymentPlanGWS-core-step2 {
        div {
            &[role=row] {
                >div:last-child {
                    min-width: 17vw;
                }
                >div[data-column-id="RRP (Inc GST)"] {
                    min-width: 8rem;
                }
                >div[data-column-id="Reseller Price (Ex GST)"] {
                    min-width: 7rem;
                }
                >div[data-column-id="Commitment Term (Billing Frequency)"] {
                    min-width: 10rem;
                }
                
            }
        }
    }
    .gws-downgrade-core-step2 {
        div {
            &[role=row] {
                >div[data-column-id="Commitment Term (Billing Frequency)"] {
                    min-width: 18rem;
                }
    
            }
        }
    }
}

@media screen and (max-width: 993px) {
.gws-changePaymentPlanGWS-core-step2 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 23vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 17vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }
        }
    }
}
}