@use '../../../../../../../../Assets/Styles/variables';
.gws-upgrade-core {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 50vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.loading-plans {
    min-height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gws-upgrade-core-step2 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div[role=headCell]:first-child {
                background-color: variables.$table-column-background-secondary;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.accordion-upgradeGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.gws-upgrade-core-step3 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 20vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 10vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.gws-upgrade-core-step4 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 22vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }
            >div:nth-child(2){
                width: auto !important;
                min-width: 15vw;
            }
            >div:nth-child(3){
                width: auto !important;
                min-width: 10vw;
            }
            >div:nth-child(4){
                min-width: 13vw;
            }
            >div:nth-child(5){
                width: auto !important;
                min-width: 7vw;
            }

            >div:last-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.css-vnkopk-MuiStepLabel-iconContainer {
    color: variables.$primary-border-color !important;
}

.gws-tooltip {
    z-index: 999999 !important;
}

.no-data-cloud-invoice {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gws-upgrade-plan-min-width {
    min-width: 15vw;
}

.upgrade-modal-height {
    max-height: 80vh !important;
    overflow-y: auto;
}

.upgrade-modal-width {
    width: 90vw !important;
    max-height: 70vh !important;
    overflow-y: auto;
}

.gws-subsription-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

@media screen and (max-width: 993px){
    .gws-upgrade-core-step4 {
        div {
            &[role=row] {
                >div:nth-child(3){
                    width: auto !important;
                    min-width: 11vw;
                    
                }
                >div:nth-child(4){
                    width: auto !important;
                    min-width: 20vw;
                    
                }
                >div:first-child {
                    width: auto !important;
                    min-width: 18vw;
                }
               >div:last-child{
                min-width: 16vw;
               }
            }
        }
    } 
}

@media only screen and (min-width: 992px) and (max-width: 1024px)
{
    .gws-upgrade-core-step4 {
        div {
            &[role=row] {
                >div:nth-child(3){
                    width: auto !important;
                    min-width: 11vw;
                    
                }
                >div:nth-child(4){
                    width: auto !important;
                    min-width: 19vw !important;
                    
                }
                >div:first-child {
                    width: auto !important;
                    min-width: 18vw;
                }
                >div:last-child {
                    width: auto !important;
                    min-width:16vw;
                }
               
            }
        }
    } 
}