@use '../../../../Assets/Styles/variables';
.profile-table {
  padding-left: 2rem;
  padding-right: 2rem;
}

.dropdown-disabled {
  background-color: variables.$dropdown-disabled !important;
}

.no-data-cloud-invoice {
  min-height: 40vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}