.logo {

    img {
        margin-bottom: 2.5rem;
    }

    p {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}

p {
    line-height: 1.5;
}

@media screen and (max-width: 727px) {
    .logo {
        text-align: center;
        min-height: 100vh;
        margin-top: 0px !important;
        padding: 4rem 2rem;

        img {
            margin-bottom: 50px;
            max-width: 13rem;
            height: auto;
        }

        .btn {
            margin-top: 2rem;
        }
    }
}