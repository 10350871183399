@use '../../../../../Assets/Styles/variables';
.gws-tooltip {
    z-index: 9999 !important;
    background: variables.$widget-background-secondary;
    color: grey;
}

.numbers {
    width: 1.3rem;
    line-height: 1.2rem;
    border-radius: 50%;
    text-align: center;
    border: 1px variables.$primary-color solid;
    background-color: transparent;
    color: variables.$primary-color;
    font-weight: 500;
    font-size: 0.75rem;
}

.popup-debit-card {
    height: 32rem !important;
    overflow-y: scroll;
}

.status-orderhistory {
    text-align: center;
}

.no-data-cloud-delete {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vertical-align-middle {
    vertical-align: middle;
}

.view-doc {
    text-decoration: none !important;
}

.no-data-cloud-invoice {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.card-payment {
    background-color: #F6F6F6 !important;
    border: none;
     width: 18rem;
}

.success-card {
    background-color: #FFECCF !important;
    color: #D47F00 !important;
}

.pending-card {
    border: 1px solid #D47F00 !important;
}

.loading-customers {
    min-height: 9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.width-accountname {
    width: 39rem !important;
}

.width-type {
    width: 13rem !important;
}

.width-status {
    width: 13rem !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .width-type {
        width: 13rem !important;
    }
    .no-data-cloud-invoice {
        min-height: 19vh;
    }
    .card-payment {
      width: 13rem;
    }
}
//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {   
    .card-payment {
      width: 13rem;
    }
    .file-upload-iphone-pro{
        width:74%;
    }
}
@media screen and (max-width: 767px) {
    .mobile-display{
        display: none;
    }
   .width-type{
    min-width: 12rem !important;
   }
}