@import url('../../../../../Assets/Styles/filterBox.scss');

.filter-column-height {
    min-height: 60vh !important;
}

@media screen and (max-width: 767px) {
    .filter-column-height {
        min-height: 0vh !important;
    }
}