@use '../../../../../../Assets/Styles/variables';
.no-data-cloud-invoice {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.productlisting-main-table {
    border-top: 2px solid variables.$primary-border-color;
}

.top-heading-border {
    border-top: 2px solid variables.$primary-border-color;
}

.big-width {
    max-width: 4rem;
}

.loading-plans {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.small-width {
    max-width: 2rem;
}

.csp-change-status-core-table {
    // max-height: 34vh;
    // overflow: auto;
    // height: auto;
    // flex-grow: 2;
    margin-bottom: 0 !important;

    tbody tr td:first-child {
        width: 14rem !important;
        // padding: 0;
    }

    td:nth-child(4) {
        width: 8rem !important;
    }

}

.azure-plan-selected {
    padding: 0 !important;
}

.csp-purchase-addon-count-row-inner {
    padding: 1rem 1rem !important;
}

.empty-selected-data {
    height: 4.5rem !important;
}

.no-data-cloud-invoice {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.table-scroll {
    max-height: 16rem !important;
    overflow: auto;
}

.csp-purchase-addon-count-row {
    background: variables.$text-color-light;
    flex-grow: 1;
    margin-bottom: 0;

    >div {
        border: 2px solid variables.$primary-border-color;
        border-radius: 0 0 0.75rem 0.75rem;
        background: variables.$text-color-light;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }
}