@use '../../../../../../../Assets/Styles/variables';
.status-customers {
    text-align: center;
}

.csp-vendor-id {
    // padding-left: 1.5rem;
    font-size: 0.75rem !important;
    color: variables.$secondary-border-color;
    text-transform: uppercase;
}


.customer-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 9vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 7.5vw !important;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }


            >div[data-column-id="Auto Renew"] {
                min-width: 1vw;
            }
            >div[data-column-id="Period"] {
                min-width: 1vw;
            }

            >div[data-column-id="Billing Term"] {
                min-width: 10.5vw;
            }

            >div[data-column-id="Days Remaining"] {
                min-width: 6.5vw;
            }

            >div[data-column-id="Plan"] {
                min-width: 12vw;
            }

            >div[data-column-id="Start Date"] {
                min-width: 8vw;
            }

            >div[data-column-id="End Date"] {
                min-width: 7.5vw;
            }

            >div[data-column-id="Quantity"] {
                min-width: 2vw;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

// for billing term(commitment term)
.gws-tooltip {
    z-index: 999999 !important;
}

.no-data-customers {
    min-height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bar-chart-height {
    height: 65vh;
}

.dropdown-disabled {
    background-color: variables.$text-color-light !important;
}

.user-management-table-inner {
    &.table-details {
        min-height: 22rem;
    }
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
    .bar-chart-height {
        height: 43vh;
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 58rem;
        }
    }

    .search-tablet-view {
        align-items: baseline !important;
    }

    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 10vw !important;
                }


                >div[data-column-id="Quantity"] {
                    min-width: 8vw;
                }

                >div[data-column-id="Status"] {
                    min-width: 1vw;
                }

                >div[data-column-id="Billing Term"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Start Date"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="End Date"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Days Remaining"] {
                    min-width: 15vw !important;
                }
            }
        }
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .bar-chart-height {
        height: 43vh;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 58rem;
        }
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 10vw !important;
                }

                >div[data-column-id="Quantity"] {
                    min-width: 8vw;
                }

                >div[data-column-id="Status"] {
                    min-width: 1vw;
                }

                >div[data-column-id="Billing Term"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Start Date"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="End Date"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Days Remaining"] {
                    min-width: 15vw !important;
                }
            }
        }
    }
}