@use "../../../../../Assets/Styles/variables";
.credit-notes-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                // min-width: auto;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }

    .btn-wrapper {
        text-align: right;
    }
}

.no-data-cloud-credit-notes-details{
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 1200px) {
    .credit-notes-details-table .cloud-summery-invoice div[role=row]>div:last-child {
        flex: 1 0 !important;
    }
}

@media screen and (max-width: 993px) {
    .credit-notes-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 15vw;
                }

                >div:last-child {
                    width: auto !important;
                    min-width: 10vw;
                }

                &:hover {
                    opacity: 1;
                    background: variables.$table-hover-color !important;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .credit-notes-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 40vw;
                }

                >div:last-child {
                    width: auto !important;
                    min-width: 30vw;
                }

                &:hover {
                    opacity: 1;
                    background: variables.$table-hover-color !important;
                }
            }
        }

        .subscription-heading-table-header {
            font-size: small !important;
        }

        .btn-wrapper {
            text-align: left;
        }

        .mobile-table {
            float: left !important;
            margin-top: 2rem;

            td {
                text-align: left !important;
            }
        }
    }
}

div[data-column-id="QTY"] {
    width: auto !important;
    min-width: 3rem !important;
    max-width: 5rem !important;
}

div[data-column-id="Description"] {
    width: auto !important;
    min-width: 30vw !important;
    max-width: 30rem !important;
}

div[data-column-id="Active Qty"] {
    width: auto !important;
    min-width: 5rem !important;
    max-width: 10rem !important;
}

div[data-column-id="Refund Days"] {
    width: auto !important;
    min-width: 5rem !important;
    max-width: 10rem !important;
}

div[data-column-id="Prorate Price (Ex GST)"] {
    width: auto !important;
    min-width: 12rem !important;
    max-width: 15rem !important;
}

.has-serial-number {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 5rem;
            }
        }
    }
}