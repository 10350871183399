@use '../../Assets/Styles/variables';
.contact-us-drawer-outer {
    height: 100vh;
    z-index: 99999 !important;
    .MuiPaper-root {
        height: 100%;
    }
}

.contact-us-drawer-main {
    width: 50vw !important;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
}

.contact-us-drawer-query-main {
    margin-top: 0rem;
    margin-bottom: 0rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    >form {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .contact-form-buttons {
            height: auto;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
        }
    }
}

.right-overlay-btn {
    .btn.btn-primary,
    .popup-content .btn.btn-primary {
        background: variables.$primary-color;
        border-color: variables.$primary-color;
    }

    .btn,
    .popup-content .btn {
        height: 2.5rem;
        border-radius: 3rem;
        line-height: 1;
        font-size: 0.875rem;
        font-weight: 500;
        min-width: 100px;
        padding: 8px 10px;
    }

    .btn-border-light {
        background: variables.$text-color-light;
        border: 1px solid variables.$primary-color;
        color: variables.$primary-color;
    }

    .btn:disabled,
    .btn.btn-disabled {
        background-color: variables.$primary-border-color;
        border-color: variables.$primary-border-color;
        color: variables.$secondary-border-color;
    }
}

.contact-us-drawer-contact-heading {
    font-size: 1rem;
}

.contact-form-height {
    height: auto;
    overflow-y: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.contact-form-tab {
    height: 90vh;
    display: flex;
    flex-direction: column;

    .tab-panels-navigation {
        height: 3rem;
        display: flex;
        align-items: center;
    }

    .tabs-content-wrapper {
        height: calc(100% - 3rem);
    }

    .tab-content {
        display: flex;
        flex-direction: column;
        height: 100%;

        .tab-pane {
            height: 100%;

          
        }
    }
}

.dropdown-menu-contact {

    .dropdown-menu {
        max-height: 30vh;
        overflow: auto;
    }
}

.contact-list-content {
    height: 100%;
    overflow-y: auto;

    .row {
        margin: 0;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {

    .contact-us-drawer-main {
        width: 70vw !important;
        height: 100vh;
    }
}

@media screen and (max-width: 767px) {
    .contact-us-drawer-main {
        width: 100vw !important;
    }

    .contact-us-drawer-query-main {
        overflow-y: auto;
        max-height: 90vh !important;
        height: 88vh;
    }

    .contact-form-tab {
        height: 95vh;
        align-content: flex-start;
    }

    .contact-form-height {
        height: 80vh;
        max-height: 80vh;
        overflow-y: auto;
    }
}