@use '../../../../../../../../Assets/Styles/variables';
.gws-upgrade-core {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 50vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.table-height-fix{
    height: 10rem;
}
.active-nav-link {
    background-color: #EFF5FC !important;
}
.card-height{
    height: auto;
}
.plan-width {
    width: 20rem !important;
}

.vendor-subscription-width {
    width: 19rem !important;
}
.dates-coterm-height{
    height: 15rem;
    overflow-y: scroll;
}

.loading-plans {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.loading-plans-card{
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.gws-tooltip {
    z-index: 999999 !important;
}

.no-data-cloud-invoice {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gws-upgrade-core-step2 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div[role=headCell]:first-child {
                background-color: variables.$table-column-background-secondary;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.accordion-upgradeGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.gws-upgrade-core-step3 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 16vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.gws-upgrade-core-step4 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 22vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.css-vnkopk-MuiStepLabel-iconContainer {
    color: variables.$primary-border-color !important;
}

.gws-upgrade-plan-min-width {
    min-width: 15vw;
}

@media screen and (max-width: 1050px) {
    .modal-width {
        width: 100vw !important;
    }
}
//ipad pro screen size
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .gws-subsription-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 20vw;
                }
            }
        }
    }
}