.main-page-not-found{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.image-page-not-found{
    height: 16.875rem;
    width: auto;
}