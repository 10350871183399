@use '../../../Assets/Styles/variables';
.cloud-terms-and-condition{
    a{
        span{
            color: variables.$primary-color;
        }
        
    }
    .flex-container {
        padding: 10px 0;
       display: -webkit-box;
       display: -moz-box;
       display: -ms-flexbox;
       display: -webkit-flex;
       display: flex;
       padding-top: 1rem;
       padding-bottom: 1rem;

   }
   .flex-container:nth-child(even) {
       background:#eee;
       padding-top: 1rem;
       padding-bottom: 1rem;
   }

   li {
    list-style: none;
    color: variables.$primary-color;
    padding-top: 0.7rem;
    span{
        font-size: 1rem;
        color: #585858;
    }
    a{
        color: variables.$primary-color;
        text-decoration: none;
    }
    }
}