@use '../../../../../Assets/Styles/variables';
.service-management-acronis-loading-view-cloud-details {
    min-height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.service-management-acronis-message {
    white-space: normal;
    word-break: break-all;
    max-height: 10vh;
    overflow-y: auto;
}

.service-management-acronis-portal-link {
    a {
        color: variables.$primary-color !important;
        font-weight: 500;
    }
}

.service-management-acronis-body-sub {
    width: 100%;
    clear: both;

    .white-box {
        background: variables.$text-color-light;
        height: 100%;
        border-radius: 0.875rem;
        padding: 1rem;
        box-shadow: 4px 4px 4px 0px rgba(223, 223, 223, 0.25);
    }
}

.service-management-rules {
    margin-top: 0.5rem;

    li {
        margin-bottom: 0.5rem;
    }
}

.service-management-heading {
    font-size: 1.25rem !important;
    font-weight: 500;
}

.no-data-user {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-weight: bold;
    background-color: variables.$text-color-light;
    box-shadow: 4px 4px 4px 0px rgba(223, 223, 223, 0.25);
    border-radius: 0.75rem;
}

.upgrade-acronis-tooltip{
    z-index: 999999 !important;
}