.scroll-to-top {
  position: fixed;
  bottom: 15rem;
  right: 1rem;
  z-index: 1000;
  cursor: pointer;

  .scroll-to-top-arrow {
    background: url('../../Assets/Images/Icons/goto-top.svg') no-repeat center;
    background-size: 6rem;
    width: 8rem;
    height: 8rem;
    display: inline-block;
    border-radius: 100%;
    font-size: 0;
  }
}