@use '../../../../../Assets/Styles/variables';
.status-customers {
    text-align: center;
}

.csp-vendor-id {
    font-size: 0.75rem !important;
    color: variables.$secondary-border-color;
    text-transform: uppercase;
}


.renewal-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                // min-width: 16vw;
                min-width: 10vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                min-width: 11vw !important;
                padding-left: 0rem;
                padding-right: 2rem;
            }


            >div[data-column-id="Auto Renew"] {
                min-width: 9vw;
                padding-left: 0;
                
                .text-success {
                    color: #28a745 !important;
                    font-weight: 500;
                }
                
                .text-danger {
                    color: #dc3545 !important;
                    font-weight: 500;
                }
            }

            >div[data-column-id="Commitment Term (Billing Frequency)"] {
                min-width: 10vw;
            }

            >div[data-column-id="Days Remaining"] {
                // min-width: 2vw;
            }

            >div[data-column-id="Plan"] {
                min-width: 12vw;
                padding-right: 1rem;
            }

            >div[data-column-id="Start Date"] {
                min-width: 8vw;
                padding-right: 0rem;
                padding-left: 0rem;
            }

            >div[data-column-id="End Date"] {
                min-width: 8vw;
                padding-right: 0rem;
                padding-left: 0rem;
            }

            >div[data-column-id="Quantity"] {
                min-width: 3vw;
                padding-right: 1.5rem;
                padding-left: 0rem;
                margin-left: -2rem;
            }

            >div[data-column-id="Service"] {
                min-width: 8vw;
            }

             >div[data-column-id="Days Remaining"] {
                padding-left: 0;
                min-width: 10vw;
            }

            // Force single line for all column headers
            div[role=columnheader] {
                overflow: hidden;
                text-overflow: ellipsis;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

// for billing term(commitment term)
.gws-tooltip {
    z-index: 999999 !important;
}

.no-data-customers {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bar-chart-height {
    height: 65vh;
}

.dropdown-disabled {
    background-color: variables.$text-color-light !important;
}

.renewal-settings-table-inner {
    &.table-details {
        min-height: 22rem;
    }
}


// @media only screen and (min-width: 360px) and (max-width: 991px) {
@media only screen and (max-width: 767px){

    .renewal-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 30vw;
                }

                >div:last-child {
                    // min-width: 10vw !important;
                        padding: 0 !important;
                        min-width: 0 !important;
                }


                >div[data-column-id="Quantity"] {
                    min-width: 8vw;
                }

                >div[data-column-id="Status"] {
                    min-width: 1vw;
                }

                >div[data-column-id="Plan"] {
                    min-width: 12rem;
                    padding-left: 0.5rem;
                }

                >div[data-column-id="Auto Renew"] {
                    min-width: 8rem !important;
                }

                >div[data-column-id="Start Date"] {
                    min-width: 6rem !important;
                }

                >div[data-column-id="End Date"] {
                    min-width: 6rem !important;
                }

                >div[data-column-id="Days Remaining"] {
                    min-width: 32vw !important;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .renewal-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 16vw;
                }

                >div:last-child {
                    min-width: 18vw !important;
                }

                >div[data-column-id="Quantity"] {
                    min-width: 8vw;
                }

                >div[data-column-id="Plan"] {
                    min-width: 18vw;
                }

                >div[data-column-id="Status"] {
                    min-width: 1vw;
                }

                >div[data-column-id="Auto Renew"] {
                    min-width: 13vw;
                }

                >div[data-column-id="Commitment Term (Billing Frequency)"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Start Date"] {
                    min-width: 12vw !important;
                }

                >div[data-column-id="End Date"] {
                    min-width: 12vw !important;
                }

                >div[data-column-id="Days Remaining"] {
                    min-width: 15vw !important;
                }
            }
        }
    }

    .d-md-px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .bar-chart-height {
        height: 43vh;
    }

    .renewal-settings-table-inner {
        &.table-details {
            min-height: 58rem;
        }
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .renewal-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 14vw;
                }

                >div:last-child {
                    min-width: 16vw !important;
                }

                >div[data-column-id="Quantity"] {
                    min-width: 8vw;
                }

                >div[data-column-id="Service"] {
                    min-width: 10vw !important;
                }

                >div[data-column-id="Status"] {
                    min-width: 1vw;
                }

                >div[data-column-id="Commitment Term (Billing Frequency)"] {
                    min-width: 11vw !important;
                }

                >div[data-column-id="Start Date"] {
                    min-width: 12vw !important;
                }

                >div[data-column-id="End Date"] {
                    min-width: 12vw !important;
                }

                >div[data-column-id="Days Remaining"] {
                    min-width: 14vw !important;
                }
            }
        }
    }

    .d-md-px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media screen and (max-width: 767px) {

   .cloud-summary-table {
        overflow: hidden;
    }
}

@media screen and (max-width: 767px) {

    .renewal-settings-table {
        overflow: hidden;
    }
}

.subscription-details-modal {
    &.popup-content {
            
            .content {
                .popup-label {
                    white-space: nowrap;
                }
            }
    }
}

.popup-label {
    white-space: nowrap !important;
}


:global {
    #subscription-details-tooltip {
        opacity: 1 !important;
        padding: 0 !important;
        background: none !important;
        max-width: none !important;
        border: #0d6efd !important;
        
        .tooltip-inner {
            max-width: none !important;
            background: none !important;
            padding: 0 !important;
            border: 1px solid #0d6efd !important; // Add blue border
        }
        
        .tooltip-arrow {
            display: none !important;
        }
    }

     .dynamic-options-position {
    position: absolute;
    top: 7px;
     }

     .dynamic-options-position-wide {
    position: absolute;
    top: 7px;
    width: 100%;
    }
    

}

@media only screen and (max-width: 767px) {
    .dropdown-services-renewal {
        margin-bottom: 0.5rem !important;
      }
    .dropdown-duration-renewal {
        margin-top: -1.5rem !important; 
      } 
    .dropdown-menu-mobile-options{
         border: 1px solid #dee2e6 !important;
         width: 100%;
         max-height: 200px !important;
         overflow-y: auto !important;
    }  
}
    
// iPad and smaller laptops (768px to 1366px)
@media only screen and (min-width: 768px) and (max-width: 1245px) {
    .d-md-px-0 {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


@media only screen and (min-width: 1025px) {
    .d-lg-px-1 {
        padding-left: 0.25rem !important;
        padding-right: 0.77rem !important;
    }
}

@media only screen and (max-width: 1199px) {
  .info-icon {
    display: none !important;
  }
}


.search-dropdown {
        .search-dropdown-options
        {
            border: 1px solid #dee2e6 !important;
            min-height: 0 !important;
            // top: -32px !important;
        }
        
        @media only screen and (max-width: 767px) {
            .search-dropdown-options {
                max-height: 180px !important;
                width: 100% !important;
            }
        }
    }


   


