.filter-column-height {
    min-height: 44vh !important;
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .filter-column-height {
        min-height: 10vh;
    }

    .filter-integration {
        top: 5rem !important;
    }
}

@media screen and (max-width: 767px) {
    .filter-column-height {
        min-height: 0vh !important;
    }

    .filter-agreement-mobile {
        max-height: calc(94% - 50px) !important;
    }
}