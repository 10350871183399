@use '../../../Assets/Styles/variables';
.layout {
  height: 10vh;
  width: 100%;

  div {
    border-radius: 0.75rem;
    background-color: variables.$text-color-light;
    padding: 0.5rem;
  }
}

.widget-header {
  border: none !important;
  width: 100%;
}

.widget-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recent-customers {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.1rem;
}


.widget-header {
  border: none !important;
  width: 100%;
}

.widget-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 18rem;
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 1rem;
  margin-bottom: 1rem;
}

.widget-body-no-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  height: 18rem;
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 1rem;
  margin-bottom: 1rem;
}

.recent-customers {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.1rem;
}

.monthly-summary-box {
  background-color: variables.$text-color-light;
  margin: 0.5rem;
  border-radius: 0.75rem;

  span {
    color: variables.$secondary-neutral-color !important;
  }
}

.monthly-summary {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.8rem;
  margin-top: 1rem;
}

.left-recent-customers {
  width: 60%;
}

.add-widget-border {
  background-color: variables.$text-color-light;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='14' ry='14' stroke='black' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 0.75rem;
}

@media only screen and (max-width: 767px) {
  .left-recent-customers {
    width: 100% !important;
  }

  .button-tab-view {
    font-size: 0.75rem !important;
    min-width: 81px !important;
  }

  .dashboard_widget-body {
    margin-top: 1rem;
  }

  .button-mobile {
    text-align: right;
    padding-right: 3rem;
  }

  .left-button {
    width: 90%;
  }

}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .left-widgets-ipad-pro {
    width: 35%;
  }

  .right-widgets-ipad-pro {
    width: 65%;
  }

  .individual-widgets-ipad-pro {
    width: 100%;
  }

  .top-heading-ipad-pro {
    width: 45%;
  }
}