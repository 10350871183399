@import url('../../../../../../Assets/Styles/filterBox.scss');
.filter-column-height{
    min-height: 60vh;
}
@media only screen and (max-width: 992px) and (min-width: 768px) {
    .filter-column-height{
        min-height: 10vh ;
    }
}
@media screen and (max-width: 768px){
    .filter-column-height{
        min-height: 0 !important ;
    }
}