.closeCross{
    text-align: right;
    padding: 1rem;
    padding-bottom: 0;
    position: absolute;
    right: 2rem;
    top:1.3rem;
   }

   .loading-customers{
    height: 35rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}