@use '../../../../Assets/Styles/variables';
// Logo Block

.our-vendors-details-component {
    min-height: 80vh;

    h1 {
        font-size: 2.75rem;
        font-weight: 400;
        line-height: 3.5rem;
    }

    h2 {
        font-size: 2.75rem;
        font-weight: 400;
        // line-height: 3.5rem;
    }

    p {
        font-size: 1.125rem;
        line-height: 1.75rem;
        color: #565656;
    }

    .vendor-image-block{
        width: 75%;
    }

    ul {
        padding-left: 0;
        li {
            background: url('../../../../Assets/Images/Icons/fill-circle-blue.svg') no-repeat left 0.3rem;
            padding-left: 2rem;
            list-style: none;
            background-size: 1rem;
            line-height: 1.5rem;
            font-size: 1.125rem;
            color: variables.$primary-neutral-text-color;
            margin-bottom: 0.75rem;
        }
    }

    .vendor-logo-block {
        background: variables.$widget-background-secondary;
        padding: 1rem 0 1rem;
    }

    .vendor-brand-block {
        background: variables.$widget-background-secondary;
        padding-top: 1rem;
        padding-bottom: 2rem;

        > .container {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        .gotoback {
            background: url("../../../../Assets/Images/Icons/back-arrow-black-big.svg") no-repeat left top;
            padding-left: 1.25rem;

            span {
                font-size: 2rem;
            }
        }

        p {
            font-size: 1rem;
            color: #565656;
        }

        .vendor-logo {
            display: flex;
            align-items: center;
            justify-content: end;

            img {
                height: 7rem;
                max-width: 18rem;
            }
        }
    }


    .vendor-aboutus-block {
        background: variables.$text-color-light;
        padding-top: 2rem;
        padding-bottom: 2rem;

        > .container {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        img {
            padding-right: 2rem;
            margin-bottom: 2rem;
        }
    }

    .vendor-services-block {
        background: variables.$widget-background-secondary;
        padding-top: 2rem;
        padding-bottom: 2rem;

        > .container {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        .vendor-service {
            padding: 0 1.5rem;
            margin-bottom: 2rem;

            img {
                border-radius: 100%;
                background: variables.$text-color-light;
                width: 60%;
                height: auto;
                margin: 1rem auto;
            }

            h3 {
                font-size: 1.5rem;
                font-weight: 600;
            }

            p {
                color: #04242D;
                font-size: 1rem;
            }
        }
    }


    .vendor-business-block {
        background: variables.$text-color-light;
        padding-top: 2rem;
        padding-bottom: 2rem;

        > .container {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        img {
            padding-left: 2rem;
            margin-bottom: 2rem;
        }
    }

    .vendor-campaign-block {
        background: variables.$background-secondary-color;
        padding-top: 2rem;
        padding-bottom: 2rem;

        > .container {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        img {
            padding-right: 2rem;
            margin-bottom: 2rem;
        }

    }


    .vendor-faq-block {
        background: variables.$text-color-light;
        padding-top: 2rem;
        padding-bottom: 2rem;

        h2 {
            text-align: center;
            font-size: 1.875rem;
            line-height: 2.5rem;
            font-weight: 500;
        }

        .accordion-panel {
            border-bottom: 0px;
        }

        .faqs-accordion {
            border-radius: 0px !important;
            border-top: 1px solid #EBEAEB;

            &:last-child {
                border-bottom: 1px solid #EBEAEB;
            }

            .accordion-header {
                button {
                    font-size: 1.125rem;
                }
            }
        }
    }

    .vendor-contact-us-block {
        background: #071F2F;
        padding-top: 2rem;
        padding-bottom: 2rem;
        margin-bottom: -2rem;

        > .container {
            padding-left: 3rem;
            padding-right: 3rem;
        }

        input:-webkit-autofill,
        input:-moz-autofill,
        input:-o-autofill,
        input:-ms-autofill {
            background-color: transparent !important;
            color: variables.$text-color-light!important;
        }

        .custom-dropdown {
            background-color: transparent;
            border: 1px solid variables.$secondary-border-color;
        }

        * {
            color: variables.$text-color-light;
        }

        .red {
            color: variables.$primary-danger;
        }

        .custom-dropdown.selected-color>a:hover {
            color: variables.$text-color-light;
        }

        .btn-border-light {
            color: variables.$primary-color;
        }

        .form-control {
            border: 1px solid variables.$secondary-border-color;
            background: transparent;

            &:hover,
            &:focus {
                color: variables.$text-color-light !important;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .our-vendors-details-component {
        .vendor-business-block, .vendor-aboutus-block  {
            img {
                padding: 0rem !important;
            }
        }

        .vendor-campaign-block {
            img {
                padding-right: 0rem;
            }

        }
    }
}