@use '../../../../../../../../Assets/Styles/variables';
.accordion-upgradeCSP {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.loading-plans {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.csp-edit-quantity-table {
    div {
        max-height: 50vh;
    }

    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 10vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.csp-purchase-addon-table-outer {
    display: flex;
    flex-direction: column;
}

.csp-tooltip {
    z-index: 999999 !important;
}

.csp-purchase-addon-table {
    max-height: 34vh;
    overflow: auto;
    height: auto;
    flex-grow: 2;


    >table {
        margin-bottom: 0rem;
    }
}

.csp-purchase-addon-count-row {
    background: variables.$text-color-light;
    flex-grow: 1;
    margin-bottom: 0;

    >div {
        border: 1px solid #3599DA;
        border-radius: 0 0 0.75rem 0.75rem;
        background: variables.$text-color-light;
        padding: 0.5rem 1rem;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }
}

.csp-edit-quantity-table-order-confirmation {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 18vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }



            >div:last-child {
                width: auto !important;
                min-width: 8vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            >div[data-column-id="Delete"] {
                min-width: 5rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.edit-quantity-width {
    width: 90vw !important;
    max-height: 70vh !important;
    overflow-y: auto;
}

.big-width-rrp{
    width: 8rem !important;
}
.big-width-reseller-price{
    width: 11rem !important;
}

.gws-subsription-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 25vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.show-qty-btn {
    outline: none;
    -webkit-appearance: none;
    background-color: transparent;
    border: none;
    align-items: center;
    justify-content: center;
    width: calc(100% - 1.5rem);
    height: 100%;
    cursor: pointer;
    margin: 0;
    position: relative;
    display: flex;
    line-height: 1.25rem !important;
    padding: 0;
    font-size: 1.125rem !important;
    color: variables.$secondary-neutral-color;
    text-align: center;
}

.btn-wrapper {
    width: 1.5rem;
    height: 2.5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-left: 1px solid variables.$primary-border-color;
    border-right: 1px solid variables.$primary-border-color;
    align-items: center;
    border-radius: 0 6px 6px 0;
    margin-left: -1.5rem;
    border: 1px solid variables.$primary-border-color;
    overflow: hidden;
    background: variables.$text-color-light;
}

.csp-purchase-addon-table {
    th {
        padding: 1rem;
        background-color: rgb(250, 250, 250);
        font-weight: 500;
        font-size: 0.875rem;
        text-transform: capitalize;
        color: rgb(106, 106, 106) !important;
        vertical-align: middle;

        >span {
            background: transparent !important;
            background-color: none !important;
            white-space: nowrap;
            padding: 0 !important;
        }
    }

    td {
        padding: 1rem;
        vertical-align: middle;

        &:first-child,
        &:last-child {
            padding: 1rem !important;
        }
    }
}


.qty-wrapper {
    min-width: 10rem;
}

.show-qty-btn-wrapper {
    border: 1px solid variables.$primary-border-color;
    display: inline-flex;
    border-radius: 6px;
    overflow: hidden;
    background: variables.$text-color-light;
    width: 5rem;
    align-items: center;
    margin-right: 3rem;

    label {
        border-right: 0px !important;
        border: 0px;
        margin-right: 1.5rem;
        flex-grow: 2;
        width: calc(100% - 1.5rem);
        text-align: center;
        font-size: 0.875rem !important;
        background: variables.$text-color-light;

        &:focus {
            outline: none;
        }
    }

    .btn-wrapper {
        width: 1.5rem;
        height: 2.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        border: 0px !important;
        border-left: 1px solid variables.$primary-border-color !important;
        align-items: center;
        border-radius: 0 6px 6px 0;
        margin-left: -1.5rem;
        border: 1px solid variables.$primary-border-color;
        overflow: hidden;
        background: variables.$text-color-light;

        .plus {
            border-bottom: 1px solid variables.$primary-border-color !important;

            &.disabled {
                border-radius: 0 6px 0 0;
            }
        }

        .minus {

            span {
                width: 0.5rem;
                height: 2px;
                background: variables.$secondary-neutral-color;
            }

            &.disabled {
                border-radius: 0 0px 6px 0;

                span {
                    width: 0.5rem;
                    height: 2px;
                    background: variables.$table-column-background-secondary;
                }
            }
        }
    }

    button {
        outline: none;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.25rem;
        cursor: pointer;
        margin: 0;
        position: relative;
        display: flex;
        line-height: initial;
        padding: 0;
        font-size: 1.125rem;
        color: variables.$secondary-neutral-color;


        &.disabled {
            background-color: variables.$button-disabled-background;
            cursor: not-allowed;
        }
    }
}

.selected-row {
    td {
        background: variables.$table-row-hover;
    }
}

//ipad pro screen size
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .gws-subsription-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 20vw;
                }
            }
        }
    }
}

.commitment-term-width {
    width: 10rem !important;
}
.no-data-cloud-invoice {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.no-data-cloud-summary {
    min-height: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}