@use '../../../../../Assets/Styles/variables';
.layout{
    height: 10vh;
    width: 100%;
     div{
        border-radius: 0.75rem;
        background-color: variables.$text-color-light;
        padding: 0.5rem;
     }
  }
  .widget-header{
    border: none !important;
    width: 100%;
  }
  .widget-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .recent-customers{
    border-radius: 0.75rem;
        background-color: variables.$text-color-light;
        padding: 0.5rem 0.1rem;
  }
  .monthly-summary-box{
    background-color: variables.$widget-background-secondary;
    margin: 0.5rem;
    border-radius: 0.75rem;
    span{
        color: variables.$secondary-neutral-color !important;
    }
  }
  .monthly-summary{
    border-radius: 0.75rem;
    background-color: variables.$text-color-light;
    padding: 0.5rem 0.8rem;
    margin-top: 1rem;
  }

  .dropdown-disabled  {
    background-color: variables.$text-color-light !important;
  }
  .widget-height{
    max-height: 20vh;
  }

.seats-summary-card{
    border-radius: 0.4rem;
    background-color: variables.$widget-background-secondary;
    span{
      color: variables.$secondary-neutral-color !important;  
    }
    
}


.seats-summary-div{
    width: 90%;
}
.numbers { 
    width: 1.3rem; 
    line-height: 1.2rem; 
    border-radius: 50%; 
    text-align: center; 
    border: 1px variables.$primary-color solid;
    background-color: transparent; 
    color: variables.$primary-color; 
    font-weight: 500;
    font-size: 0.75rem;
} 