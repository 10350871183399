@use '../../../../../Assets/Styles/variables';
.title {
    color: variables.$text-color-light;
    font-style: italic;
    font-weight: 700;
    font-size: 2rem;
    img{
      height: 2rem;
    }
    span{
      color: variables.$text-color-light;
      font-style: normal;
      font-size: 1.25rem;
    }
    &:hover {
      color: variables.$text-color-light;
    }
  }

  .profileIconWhite{
    path{
      color: variables.$text-color-light !important;
    }
    
  }

  .profileIconBlue{
    path{
      color: variables.$landing-page-primary-color !important;
    }
    
  }

  .navbarSubNavLink{
    width: 100%;
  }

  .navLink {
    margin-top: 0;
    color: variables.$text-color-light;
    &:hover {
      color: variables.$text-color-light;
    }
    &:active {
      color: variables.$text-color-light !important;
      font-weight: bold;
    }
    &:visited{
      color: variables.$text-color-light !important;
    }
  }

  // .navLink .active{
  //   margin-top: 0;
  //   color: variables.$text-color-light;
  //   &:hover {
  //     color: variables.$text-color-light;
  //   }
  // }
  
  .button-container {
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .reseller-btn {
    background-color: transparent;
    color: variables.$text-color-light;
    border-radius: 0.375rem;
    border: 0.125rem solid variables.$text-color-light;
    padding: 0.125rem 0.375rem;
    cursor: pointer;
     margin-right: 0.625rem;
  }
  
  .login-btn {
    background-color: variables.$text-color-light;
    border-radius: 0.375rem;
    border: 0.125rem solid variables.$text-color-light;
    padding: 0.125rem 0.563rem;
    cursor: pointer;
    color: variables.$landing-page-primary-color;
  }
  .closeCross{
   text-align: right;
   padding: 1rem;
  }
  .form-group {
    text-align: left;
    padding: 0 0.313rem;
    background: linear-gradient(to right, #003366, #0073e6, #00c8ff); 
    -webkit-background-clip: text;
    color: transparent;
    
  }
  .form-label{
    background: linear-gradient(to right, #003366, #0073e6, #00c8ff); 
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 600;
  }
  .cross{
    color: variables.$landing-page-primary-color !important;
  }
  .popup-h1{
    text-align: left;
    background: linear-gradient(to right, #003366, #0073e6, #00c8ff); 
    font-weight: 600;
    padding-bottom: 1.5rem;
    -webkit-background-clip: text;
    color: transparent;
  }
  .tabBtn {
    padding: 0.625rem 1.563rem;
     background-color: #006abc; 
     color: variables.$text-color-light;
     border: none;
     border-radius: 1.25rem;
     cursor: pointer;  
   }
   .checkLabel{
    font-size: 600;
    color: variables.$text-color;
   }
   .formContainer{
    width: 600px;
   }
   
  @media screen and (max-width: 767px) {
  
    .reseller-btn,
    .login-btn {
      padding: 0.063rem 0.063rem;
      font-size: 0.8rem;
   
    }
  
    .title {
      font-size: 1rem;
      justify-content: flex-end;
      margin-left: 0;
      img{
        height: 1.75rem;
      }
      span{
        color: variables.$text-color-light;
        font-style: normal;
        font-size: 1rem;
      }
      
    }

    .mobileTitleImage{
      height: 2.5rem;
    }
  
    .mobileTitle{
      font-weight: 650;
      font-size: 1.8rem !important;
      font-style: italic;
      padding-left: 5px;
    }

    .navLink {
      font-size: 0.7rem;
      text-align: center;
      padding-right: 0;
      color: variables.$landing-page-primary-color;
      font-weight: 500;
      border-bottom: 1px solid variables.$text-color-light !important;
      margin-bottom: 1rem;
    }

    .mobileNavBtn {
      background: variables.$text-color-light;
    }

    .reseller-btn {
      background-color: transparent;
      color: variables.$landing-page-primary-color;
      border-radius: 0.375rem;
      border: 0.125rem solid variables.$landing-page-primary-color;
      padding: 0.125rem 0.375rem;
      cursor: pointer;
      transform: scale(1.2);
    }
    
    .login-btn {
      background-color: variables.$landing-page-primary-color;
      border-radius: 0.375rem;
      border: 0.125rem solid 0559CA;
      padding: 0.125rem 0.563rem;
      cursor: pointer;
      color: variables.$text-color-light;
      transform: scale(1.2);
    }
    .offCanvasNavLinks{
      min-height: 5vh;
      max-height: 75vh;
      overflow-y: auto;
      overflow-x: hidden;
      padding-top: 2rem;
    }
  
  }
  