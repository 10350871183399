@use '../../../../../Assets/Styles/variables';
.vendorPortfolio {
    text-align: center;
    background-color: variables.$text-color-light;
    padding-top: 2.25rem;
    padding-bottom: 3rem;
  }
  
  .vendorImage {
    width: 100%;
    height: auto;
  }
  .vendorHeading{
      color: #73a4df;
      font-weight: bold;
      margin-bottom: 1rem;
    }
  .vendorSubHeading{
    color: #17446c;
    padding: 0.25rem;
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 500;
  }
  // h1{
  //   padding: 0.25rem;
  //   margin-bottom: 0.313rem;
  //   font-size: 2rem;
  //   font-weight: normal;
    
  // }

  @media screen and (max-width: 768px) {
    .vendorImage {
      width: 100%;
      height: 15.625rem;
    }
    .vendorSubHeading{
      font-size: 1.25rem;
    }
  }