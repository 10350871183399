@use "../../../../../../../../Assets/Styles/variables";
.gws-downgrade-core {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 50vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.loading-plans {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.accordion-downgradeGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.gws-tooltip {
    z-index: 999999 !important;
}

.gws-change-status-core-table {

    thead tr {
        border-bottom: 0px !important;

        th {
            font-size: 0.875rem !important;
            font-weight: 500 !important;
        }
    }

    tbody tr {
        td {
            font-weight: 400 !important;
            word-break: normal !important;
            font-size: 0.875rem !important;
        }
    }

}

.change-status-gws-confirmation-line strong {
    word-break: normal !important;
}

.highlight-renewal-plan-text-gws {
    font-weight: 500 !important;
}

.loading-plans {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-width {
    max-height: 70vh !important;
    overflow-y: auto;
}
.note-p{
    text-align: start !important;
}
@media only screen and (min-width: 992px) and (max-width: 1024px)
{
    .reseller-price{
        min-width:20vw;
    }
}