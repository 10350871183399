h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
}

h1 {
    font-size: 1.25rem;
}

h2 {
    font-size: 1.125rem;
}

h3 {
    font-size: 1rem;
}

h4 {
    font-size: 0.875rem;
}

h5 {
    font-size: 0.75rem;
}

p,
ul,
li,
a,
strong,
span {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.026rem;
}

label {
    font-size: 0.875rem;
    font-weight: normal;
    line-height: 1.026rem;
}

.text-regular {
    font-size: 1rem;

    >* {
        font-size: 1rem;
    }
}

.text-medium {
    font-size: 0.875rem;

    >* {
        font-size: 0.875rem;
    }
}

.text-small {
    font-size: 0.75rem !important;

    >* {
        font-size: 0.75rem !important;
    }
}

.text-large {
    font-size: 1.25rem;

    >* {
        font-size: 1.25rem;
    }
}

.text-extra-large {
    font-size: 1.5rem;

    >* {
        font-size: 1.5rem;
    }
}

.long-text {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
}