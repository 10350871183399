@use '../../../../../../../../Assets/Styles/variables';
.gws-changePaymentPlanGWS-core {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.loading-plans {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gws-changePaymentPlanGWS-core-step2 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.gws-tooltip {
    z-index: 999999 !important;
}

.no-data-cloud-invoice {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.accordion-changePaymentPlanGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.gws-changePaymentPlanGWS-core-step3 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }
            >div:nth-child(2) {
                min-width: 15vw;
            }
            >div:nth-child(4) {
                min-width: 15vw;
            }
            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.css-vnkopk-MuiStepLabel-iconContainer {
    color: variables.$primary-border-color !important;
}

.modal-width {
    width: 90vw !important;
    max-height: 70vh !important;
    overflow-y: auto;
}
.gws-subsription-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

@media screen and (max-width: 993px){
    .gws-changePaymentPlanGWS-core-step3 {
    div {
        &[role=row] {
            >div:nth-child(2) {
                min-width: 15vw !important;
            }
            >div:nth-child(4) {
                min-width: 19vw !important;
            }
            >div:last-child{
                min-width: 15vw !important;
            }
        }
    }
}
}