.sec-ops-products-addendum{
    *{
    text-align:justify;
    font-size: 0.8rem;
    }
    h2{
        font-size: 0.9rem;
    }
    body {
        counter-reset: custom-counter;
    }

    .fw-bold {
        font-weight: bold;
    }

    .main-header::before {
        counter-increment: custom-counter;
        content: counter(custom-counter) ". ";
    }

    .point {
        padding-bottom: 20px;
        padding-top: 10px;
    }

    .main-header {
        counter-reset: sub-header-counter;
    }

    .sub-header {
        padding-left: 20px;
        padding-bottom: 10px;
        padding-top: 10px;
    }

    .sub-header .heading {
        text-decoration: underline;
    }

    .padding-top-10 {
        padding-top: 10px;
    }

    .sub-header::before {
        counter-increment: sub-header-counter;
        content: counter(custom-counter)"."counter(sub-header-counter) ". ";
        font-weight: bold;
    }

    .text-decoration-underline {
        text-decoration: underline;
    }
	 .fw-paddingleft {
        padding-left:15px;
    }
}