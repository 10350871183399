@use '../../../Assets/Styles/variables';
.tags-not-integrated-featured {
    background-color: #747CED80 !important;
    color: variables.$primary-neutral-text-color !important;
    // opacity: 50%!important;
    border: none !important;
    padding: 0.5rem !important;
    height: auto !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    border-radius: 0.375rem !important;
    font-size: 0.8rem !important;
    min-width: 10px !important;
    cursor: context-menu !important;
}

.tags-integrated-featured {
    background-color: #5fcc9b37 !important;
    // opacity: 50%!important;
    color: variables.$primary-neutral-text-color !important;
    border: none !important;
    padding: 0.5rem !important;
    height: auto !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    border-radius: 0.375rem !important;
    font-size: 0.8rem !important;
    min-width: 10px !important;
    cursor: context-menu !important;
}

.tags-request-in-progress {
    background-color: rgba(255, 140, 4, 0.5) !important;
    // opacity: 50%!important;
    color: variables.$primary-neutral-text-color !important;
    border: none !important;
    padding: 0.5rem !important;
    height: auto !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    border-radius: 0.375rem !important;
    font-size: 0.8rem !important;
    min-width: 10px !important;
    cursor: context-menu !important;
}

.logo {
    width: auto !important;
    height: 100%;

}

.card {
    background-color: variables.$text-color-light;
    border-radius: 0.75rem;
}

.view-more {
    color: variables.$primary-color;
    font-weight: 500;
    height: 1rem !important;
    display: block;
}

.action-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 767px) {
    .action-button {
        display: flex;
        flex-direction: row;
        align-items: end;
    }

    .image-mobile {
        text-align: center;
    }

    .heading-mobile {
        justify-content: center;
    }

    .view-more {
        text-align: right;
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .connectwise-content-ipad-pro {
        width: 58%;
    }

    .action-button {
        width: 24%;
    }
}