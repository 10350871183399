@use '../../../../../../../Assets/Styles/variables';
.customers-table {
    padding-left: 0rem;
    padding-right: 0rem;
}

.status-customers {
    text-align: center;
}

.search-input-box-customers {
    margin-right: 0.75rem;
    max-width: 22rem !important;
}

.customers-color-underline {
    color: variables.$primary-color;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 400;
}

.no-data-found {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

div {
    &[role=columnheader] {
        >div {
            color: variables.$secondary-neutral-color;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        &:hover {
            opacity: 1;

            >div {
                color: variables.$secondary-neutral-color;
                font-size: 0.875rem;
                font-weight: 500;


            }
        }
    }
}

div {
    &[role=cell] {
        div {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;

            div {
                color: variables.$primary-neutral-text-color;
                font-size: 0.875rem;
            }
        }
    }
}



.customers-table-inner {
    div {
        &[role=row] {

            >div:first-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 7vw !important;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.loading-customers {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-data-customers {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.customers-table-inner {
    &.table-details {
        min-height: 20rem;
    }
}

@media screen and (max-width: 993px) {
    .customers-history-table {

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 10rem;
            word-break: initial;
        }
    }

    .custom-modal-customers-content {
        max-width: 500px !important;
        min-width: 300px !important;
        width: auto;
    }
}

@media screen and (max-width: 767px) {

    .custom-modal-customers-content {
        max-width: 90% !important;
        min-width: 200px !important;
        width: auto;
    }

    .customers-table {
        overflow: hidden;
    }

    .customers-history-table {

        div[role=table]>div[role=rowgroup]> {
            div[role=row]>div {
                min-width: 12rem;
            }
        }

        div[role=columnheader]>div {
            display: flex;

            .sort-icon-desc,
            .sort-icon-asc {
                background-size: 100% !important;
            }
        }

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 10rem;
            word-break: initial;
        }
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .customers-history-table {

        div[role=row]>div:last-child {
            min-width: 6rem;
            word-break: initial;
        }
    }
}

.customers-message {
    white-space: normal;
    word-break: break-all;
    max-height: 10vh;
    overflow-y: auto;
}

.ticket-table-additional-information-popup-loading {
    min-height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
        font-size: 1.2rem;
        font-weight: 500;
    }
}

.threedots:after {
    content: "\2807";
    font-size: 1rem;
}

.threeDotAnchorTag {
    text-decoration: none;
    color: variables.$actions-icon-default;
    cursor: pointer;
}

.threeDotAnchorTag:hover {
    color: variables.$actions-icon-hover;
}