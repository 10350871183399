@use "../../../../../Assets/Styles/variables";
.google-product-scheduler-main-container{
    * {
        margin: 0;
        padding: 0;
        text-indent: 0;
    }
    .fw-bold {
        font-weight: bold;
    }

    strong{
        font-weight: bold;
    }

    .text-decoration-underline {
        text-decoration: underline;
    }
    .h1,
    h1 {
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 11pt;
    }

    .p,
    p {
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
        margin: 0pt;
    }

    .s1 {
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: underline;
        font-size: 11pt;
    }

    .s2 {
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: underline;
        font-size: 11pt;
    }

    .s3 {
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .s4 {
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 10.5pt;
    }

    .s5 {
        color: #1154CC;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: underline;
        font-size: 11pt;
    }

    .s6 {
        color: #1154CC;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: underline;
        font-size: 11pt;
    }

    .a {
        color: #1154CC;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: underline;
        font-size: 11pt;
    }

    .s7 {
        color: #1154CC;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .s9 {
        color: variables.$primary-neutral-text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 12pt;
    }

    li {
        display: block;
    }

    .l1 {
        padding-left: 0pt;
        counter-reset: c1 1;
    }

    .l1>li>*:first-child:before {
        counter-increment: c1;
        content: counter(c1, decimal)". ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 11pt;
    }

    .l1>li:first-child>*:first-child:before {
        counter-increment: c1 0;
    }

    .l2 {
        padding-left: 0pt;
        counter-reset: c2 1;
    }

    .l2>li>*:first-child:before {
        counter-increment: c2;
        content: counter(c1, decimal)"."counter(c2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l2>li:first-child>*:first-child:before {
        counter-increment: c2 0;
    }

    li {
        display: block;
    }

    .l3 {
        padding-left: 0pt;
        counter-reset: d1 2;
    }

    .l3>li>*:first-child:before {
        counter-increment: d1;
        content: counter(d1, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: bold;
        text-decoration: none;
        font-size: 11pt;
    }

    .l3>li:first-child>*:first-child:before {
        counter-increment: d1 0;
    }

    .l4 {
        padding-left: 0pt;
        counter-reset: d2 1;
    }

    .l4>li>*:first-child:before {
        counter-increment: d2;
        content: counter(d1, decimal)"."counter(d2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l4>li:first-child>*:first-child:before {
        counter-increment: d2 0;
    }

    .l5 {
        padding-left: 0pt;
        counter-reset: d3 1;
    }

    .l5>li>*:first-child:before {
        counter-increment: d3;
        content: "("counter(d3, lower-latin)") ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l5>li:first-child>*:first-child:before {
        counter-increment: d3 0;
    }

    li {
        display: block;
    }

    .l6 {
        padding-left: 0pt;
        counter-reset: e1 3;
    }

    .l6>li>*:first-child:before {
        counter-increment: e1;
        content: counter(e1, decimal)". ";
        color: variables.$text-color;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
    }

    .l6>li:first-child>*:first-child:before {
        counter-increment: e1 0;
    }

    .l7 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l7>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l7>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l8 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l8>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l8>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l9 {
        padding-left: 0pt;
        counter-reset: e3 1;
    }

    .l9>li>*:first-child:before {
        counter-increment: e3;
        content: "("counter(e3, lower-latin)") ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l9>li:first-child>*:first-child:before {
        counter-increment: e3 0;
    }

    .l10 {
        padding-left: 0pt;
        counter-reset: e3 1;
    }

    .l10>li>*:first-child:before {
        counter-increment: e3;
        content: "("counter(e3, lower-latin)") ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l10>li:first-child>*:first-child:before {
        counter-increment: e3 0;
    }

    .l11 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l11>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l11>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l12 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l12>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l12>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l13 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l13>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l13>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l14 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l14>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l14>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l15 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l15>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l15>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l16 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l16>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l16>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l17 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l17>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l17>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l18 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l18>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l18>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l19 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l19>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l19>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l20 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l20>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l20>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l21 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l21>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l21>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l22 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l22>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l22>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l23 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l23>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l23>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l24 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l24>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l24>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }

    .l25 {
        padding-left: 0pt;
        counter-reset: e3 1;
    }

    .l25>li>*:first-child:before {
        counter-increment: e3;
        content: "("counter(e3, lower-latin)") ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l25>li:first-child>*:first-child:before {
        counter-increment: e3 0;
    }

    .l26 {
        padding-left: 0pt;
        counter-reset: e2 1;
    }

    .l26>li>*:first-child:before {
        counter-increment: e2;
        content: counter(e1, decimal)"."counter(e2, decimal)" ";
        color: variables.$text-color;
        font-family: Calibri, sans-serif;
        font-style: normal;
        font-weight: normal;
        text-decoration: none;
        font-size: 11pt;
    }

    .l26>li:first-child>*:first-child:before {
        counter-increment: e2 0;
    }
}