@use '../../../../../../../../../Assets/Styles/variables';
// Align End Date Screen

.coTermPanel {
    .tab-content {
      border: 0px solid variables.$primary-border-color;
      padding-left: 2rem;
  
      .tab-pane {
        border: 1px solid variables.$primary-border-color;
        border-radius: 0.5rem;
        min-height: 50%;
        height: auto;
        max-height: 90%;
        overflow: auto;
        padding: 1rem;
  
      }
    }
  
    .co-termplan-heading {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  
    .align-end-date-tabs {
  
      .coterm-data-tabs {
        max-height: 60vh;
        overflow: auto;
        overflow-y: hidden;
      }
  
      .nav-item {
        border-bottom: 1px solid variables.$primary-border-color;
  
        .nav-link {
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          color: variables.$primary-neutral-text-color;
          background: variables.$text-color-light;
          padding-top: 1rem;
          padding-bottom: 1rem;
          font-size: 1rem;
          padding-left: 0.5rem;
          margin: 0.5rem 0;
  
          span {
            color: variables.$secondary-neutral-color;
            display: block;
            font-size: 0.75rem;
          }
  
          &.active {
            background: #EFF5FC;
            color: variables.$primary-neutral-text-color;
          }
        }
      }
  
    }
  
    .box-content {
      width: 100%;
      padding: 1rem;
      border: 1px solid variables.$primary-border-color;
    }
  }
  
  .no-data-cloud-invoice {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  
  .plans-wrapper {
    height: 100%;
    overflow: hidden;
  }
  
  .plans-list {
    max-height: calc(100% - 2rem);
    overflow: auto;
  }