@use '../../../../../../../Assets/Styles/variables';
.gws-subsription-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 12vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}
.dates-coterm-height{
    height: 10rem;
    overflow-y: scroll;
}
.table-coterm-height{
    height: 9rem;
}
.commitment-term-width{
    width: 10rem !important;
}
.loading-plans {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.accordion-changePaymentPlanGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.gws-downgrade-core-step2 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 2vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div[role=headCell]:first-child {
                background-color: variables.$table-column-background-secondary;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.accordion-downgradeGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.no-data-cloud-invoice {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}


.gws-downgrade-core-step3 {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 8vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.css-vnkopk-MuiStepLabel-iconContainer {
    color: variables.$primary-border-color !important;
}

.gws-trial-to-paid-core-table {

    thead tr {
        border-bottom: 0px !important;

        th {
            font-size: 0.875rem !important;
            font-weight: 500 !important;
             }
    }

    tbody tr {
        td {
            font-weight: 400 !important;
            word-break: normal !important;
            font-size: 0.875rem !important;
        }
    }

}

.trial-to-paid-gws-confirmation-line strong {
    word-break: normal !important;
}

.highlight-renewal-plan-text-gws {
    font-weight: 500 !important;
}

.loading-plans {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gws-edit-quantity-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.renewal-settings-card {
    min-height: 100%;
}

.loading{
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

// selected dropdown option should be bold
.dropdown-selected {
    font-weight: 500;
}

// for billing term(commitment term)
.gws-tooltip {
    z-index: 999999 !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .loading{
        min-height: 22vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .no-data-cloud-invoice {
        min-height: 22vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    
.renewal-settings-card {
    min-height: 100%;
}
}