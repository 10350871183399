$background-color: #FCFCFC;
$background-secondary-color: #F7F9FD;
$text-color: #000000;
$text-color-light: #FFFFFF;
$primary-neutral-text-color: #202020;
$primary-color: #0072BC;
$primary-hover-color: #3599DA;
$secondary-color: #3599DA;
$secondary-neutral-color: #6A6A6A;
$primary-success: #1D874C;
$primary-success-hover: #D0FBDF;
$primary-danger: #9C3B35;
$primary-danger-hover: #FBD0D0;
$primary-border-color: #DDDDDD; 
$secondary-border-color: #A0A0A0;
$table-hover-color: #F6FCFE;
$button-disabled-background: #F0F0F0;
$actions-icon-default:#000000DE;
$actions-icon-hover:#5B5B5BDE;
$dropdown-disabled:#DFDFDF;
$widget-background-secondary:#EFF5FB;
$stepper-number-background:#0072BD;
$table-column-background-secondary:#FAFAFA;
$table-row-hover:#DEEBF7;
$feedback-star-color:#FAAF00;
$landing-page-primary-color:#0559CA;
$icons-secondary:#5C5C5C;
$table-row-hover-color:#0072BC;
$status-failed-bg-color:#FDECEC;
$status-success-bg-color:#ECFDF5;
$status-progress-bg-color:#FFF4E2;
$status-failed-color:#9C3B35;
$status-success-color:#1D874C;
$status-progress-color:#F9A932;

$btn-width: 120px;
$btn-height: 40px;
$block-padding: 10px;
$font-family: 'Sans-Serif';