.looker-product-addendem-main-container{
    *{
        text-align: justify;
        font-size: 0.9rem;
        }
            body {
                counter-reset: main-counter;
            }
        
            .fw-bold {
                font-weight: bold;
            }
        
            .main-header::before {
                counter-increment: main-counter;
                content: "";
            }
        
            .point {
                padding-bottom: 20px;
                padding-top: 10px;
            }
        
            .main-header {
                counter-reset: sub-header-counter;
            }
        
            .sub-header {
                padding-left: 20px;
                padding-bottom: 10px;
                padding-top: 10px;
            }
        
            .sub-header .heading {
                text-decoration: underline;
            }
        
            .padding-top-10 {
                padding-top: 10px;
            }
        
            .sub-header::before {
                counter-increment: sub-header-counter;
                content: "";
                font-weight: bold;
            }

            strong{
                font-weight: bolder;
            }
        
            .text-decoration-underline {
                text-decoration: underline;
            }
}
