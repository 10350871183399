@use '../../../../../../../Assets/Styles/variables';
.gdap-height{
    height: 25rem;
}
.create-gdap-height{
    height: 13rem;
    overflow-y: scroll;
}
.loading-subscriptions {
    min-height: 54vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.form-label-color{
    color: variables.$text-color !important;
}