.infinity-loader-wrapper {
    height: 3rem;
}

// ipad mini and air
@media only screen and (max-width: 992px) and (min-width: 768px) {
    .vendor-revnue-widget {
        >div:first-child {
            max-height: 65vh;

            >div:first-child {
                min-height: 880px;
            }
        }
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .vendor-revnue-widget {
        >div:first-child {
            max-height: 65vh;

            >div:first-child {
                min-height: 880px;
            }
        }
    }
}