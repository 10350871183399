@use '../../../Assets/Styles/variables';
.landing-page-upper {
    // background-image: url('../../../Assets/Images/Illustrations/Landing Page/// background-image/png/bg-desktop.png');
    background-size: 100% auto;
    background-position: center; 
    background-repeat: no-repeat;
    // width: 100%; 
    height: 80vh; 
}
.landing-page-upper-loading{
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    img{
        height: 7rem;
        width: auto;
        animation: opacityChange 6s infinite;
    }
}
@keyframes opacityChange {
    0%{opacity:0;height: 0rem;width: auto;}
    25%{opacity:1;height: 7rem;width: auto;}
    50%{opacity:0;height: 0rem;width: auto;}
    75%{opacity:1;height: 7rem;width: auto;}
    100%{opacity:0;height: 0rem;width: auto;}
}
.main-container{
    background-color: variables.$background-secondary-color;
    overflow-x: hidden;
}
.cards-container {
    display: flex;
    justify-content: center;
    gap: 1.25rem;
    -webkit-transform: translateY(-30%);
    -ms-transform: translateY(-30%);
    transform: translateY(-30%);
}

@media screen and  (max-width: 1024px) { 
    .landing-page-upper {
        height: 30rem;
       background-size: 100% 38rem;
        // background-image: url('../../../Assets/Images/Illustrations/Landing Page/// background-image/png/bg-tablet.png');
    }

    .cards-container {
        margin-top: -2.5rem;
      
    }
    .cards-container > div:first-child {
        margin-left: 1.25rem;
    }

    .cards-container > div:last-child {
        margin-right: 1.25rem;
    }
    .cards-container {
        -webkit-transform: translateY(-10%);
        -ms-transform: translateY(-10%);
        transform: translateY(-10%);
    }
}

@media screen and (max-width: 767px) { 
    .landing-page-upper {
        height: 25rem;
        background-size: 100% auto;
        // background-image: url('../../../Assets/Images/Illustrations/Landing Page/// background-image/png/bg-mobile.png');
    }

    .cards-container {
        -webkit-transform: translateY(-10%);
        -ms-transform: translateY(-10%);
        transform: translateY(-10%);
    }
    .cards-container > div:first-child {
        margin-left: 1.125rem;
    }

    .cards-container > div:last-child {
        margin-right: 1.125rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) { 
    .landing-page-upper {
        height: 35rem;
        background-size:100% 100%;
        // background-image: url('../../../Assets/Images/Illustrations/Landing Page/// background-image/png/bg-tablet.png');
    }
}
@media only screen and (min-width: 1401px) { 
    .landing-page-upper {
        height: 35rem;
        background-size:100% 100%;
        // background-image: url('../../../Assets/Images/Illustrations/Landing Page/// background-image/png/bg-tablet.png');
    }
}
