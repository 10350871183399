@use '../../Assets/Styles/variables';
.install-btn {
    position: fixed;
    bottom: 0px;
    background: variables.$text-color-light;
    padding: 2rem 0;
    overflow: hidden;
    z-index: 9999;
    display: none;
}

@media screen and (max-width: 1200px){
    .install-btn {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .install-btn {
        text-align: center;
        border-radius: 2.5rem 2.5rem 0 0;

        h3 {
            font-size: 1.5rem;
        }

        p {
            font-size: 1rem;
        }

        .btn-wrapper-outer {
            margin-top: 3rem;
            text-align: center;
        }
    }
}