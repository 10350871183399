@use '../../../Assets/Styles/variables';
.profile-table {
    padding-left: 2rem;
    padding-right: 2rem;
}

.my-profile-section {
    color: variables.$secondary-neutral-color;
    font-weight: 500;
}

.my-profile-last-section {
    border-top: 2px solid #e7e7e7;
}

.no-data-found {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.borderRed {
    border: 1px solid variables.$primary-danger !important;
    color: variables.$primary-danger !important;
}