@use '../../Assets/Styles/variables';
.custom-upload-btn-block {
    border: 1px solid variables.$primary-border-color;
    position: relative;
    border-radius: 2.5rem;
    overflow: hidden;
    height: 2.5rem;

    .custom-upload-btn {
        display: flex;
        padding: 1rem 1.5rem;
        background-color: variables.$primary-color;
        color: variables.$text-color-light;
        border: none;
        border-radius: 0;
        cursor: pointer;
        position: absolute;
        left: 0;
        align-items: center;
        height: 100%;
        width: auto;
    }

    input[type="file"] {
        opacity: 0;
        width: 94%;
        height: 4rem;
        position: relative;
        z-index: 9999;
    }

    .selected-file-name, .selected-file-name-disabled {
        position: absolute;
        left: 8rem;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        max-width: calc(100% - 11rem);
        text-overflow: ellipsis;
        overflow: hidden;
        width: auto;
        white-space: nowrap;
    }

    .selected-file-name-disabled {
        color: rgba(85, 85, 85, 50%);
    }

    .clear-file {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 3rem;
        text-align: center;
        line-height: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}