@use '../../../../../Assets/Styles/variables';
.card-text{
  color: variables.$text-color;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  font-size: 0.95rem;
  background-color: variables.$text-color-light;
  text-align: center;
}
.card-container {
    width: 20vw;
    padding: 0.5rem;
    height: 17rem;
    background-color: variables.$text-color-light;
    margin-bottom: 1rem;
    border: none;
    text-align: center;  
}

.card-container-body {
    background-color: variables.$text-color-light !important;
    color: variables.$text-color;
    padding: 10px;
}

.card-icon {
    height: 5rem;
    width: 5rem;
    margin-left: auto;
    margin-right: auto;
}

.card-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0rem;
}



@media screen and  (max-width: 1024px) {
    .card-container {
        width: 80%; 
      
    }

    .card-title {
        font-size: 1rem; 
    }

    .card-text {
        font-size: 0.9rem; 
    }
    .card-container {
        height: 15rem; 
    }
}

@media screen and (max-width: 767px) {
    .card-container {
        width: 100%;  
       height: auto;
    }

    .card-title {
        font-size: 0.9rem;
    }

    .card-text {
        font-size: 0.65rem; 
    }

    .card-icon {
        height: 4rem;
        width: 4rem;
        margin-left: auto;
        margin-right: auto;
    }
}