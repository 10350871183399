@use '../../../../../../../../../Assets/Styles/variables';
.main-page {
    .domain-block {
        border: 1px solid variables.$primary-border-color;
        border-radius: 6px;

        .form-control {
            border: 0px !important;
        }

        .form-control:active {
            border: 0px !important;
        }
    }
}

.domain-extension {
    font-size: 0.875rem;
    line-height: 1.026rem;
    color: variables.$primary-neutral-text-color;
    opacity: 0.8
}

.disabled-box {
    background-color: variables.$primary-border-color;

    .domain-extension {
        color: variables.$secondary-neutral-color !important;
    }

    .form-control {
        opacity: 1 !important;
        -webkit-box-shadow: 0 0 0 30px variables.$primary-border-color inset !important;
    }
}

.radio-button-helpers {
    margin-left: -0.875rem;
    transform: translateY(0.375rem);
}