@use '../../../../../Assets/Styles/variables';
.recent-customers {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.1rem;
  height: auto;
}

.left-recent-customers {
  width: 60%;
}

.big-customer-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 10rem;
  display: inline-block;
  max-width: 85%;
}

.customername-td {
  width: 80% !important;
}

.recent-customers-data {
  min-height: 13rem;

  table {
    thead tr th {
      padding: 0.5rem 1rem !important;
    }

    tbody tr {
      border-bottom: none;

      td {
        padding: 0.5rem 1rem !important;
      }
    }
  }
}

.recent-customers-loading-state {
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 13rem;
  text-align: center;
  width: 100%;
  justify-content: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .recent-customers-actions {
    min-width: 6rem !important;
  }

  .big-customer-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 15rem;
    display: inline-block;
    max-width: 85%;
  }

  .left-recent-customers {
    width: 90%;
  }
}

@media only screen and (max-width: 767px) {
  .left-recent-customers {
    width: 90% !important;
  }

  .big-customer-name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 18rem;
    display: inline-block;
    max-width: 85%;
  }

  .disable-button {
    display: none;
  }
}