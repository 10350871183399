@use '../../../../../Assets/Styles/variables';
.layout {
  height: 10vh;
  width: 100%;

  div {
    border-radius: 0.75rem;
    background-color: variables.$text-color-light;
    padding: 0.5rem;
  }
}

.no-data-customers {
  min-height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.widget-header {
  border: none !important;
  width: 100%;
  padding-top: 0 !important;
}

.widget-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recent-customers {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.1rem;
}

.monthly-summary-box {
  background-color: variables.$widget-background-secondary;
  margin: 0.5rem;
  border-radius: 0.75rem;

  span {
    color: variables.$secondary-neutral-color !important;
  }
}

.monthly-summary {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.8rem;
  margin-top: 1rem;
}

.dropdown-disabled {
  background-color: variables.$text-color-light !important;
}

.widget-height {
  max-height: 20vh;
}

.trending-plans-card {
  border-radius: 0.4rem;

}

.trending-plans-card-1 {
  background-color: rgba(216, 236, 255, 1);
}

.trending-plans-card-2 {
  background-color: rgba(216, 236, 255, 0.7);
}

.trending-plans-card-3 {
  background-color: rgba(216, 236, 255, 0.5);
}

.trending-plans-card-4 {
  background-color: rgba(216, 236, 255, 0.3);
}

.trending-plans-card-5 {
  background-color: rgba(216, 236, 255, 0.2);
}

.trending-plans-div {
  width: 90%;
  height: 30vh;
}

.numbers {
  width: 1.3rem;
  line-height: 1.2rem;
  border-radius: 50%;
  text-align: center;
  border: 1px variables.$primary-color solid;
  background-color: transparent;
  color: variables.$primary-color;
  font-weight: 500;
  font-size: 0.75rem;
}

@media only screen and (max-width: 767px) {
  .trending-plans-div {
    max-height: 10rem !important;
  }
}