@use "../../../../../../../../Assets/Styles/variables";
.accordion-upgradeGWS {
    background-color: variables.$background-secondary-color !important;
    border: none !important;
}

.loading-plans {
    min-height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.tab-header {
    font-size: 1rem;
    background-color: variables.$background-secondary-color;
}

.tab-header-top {
    font-size: 1.25rem;
}

.gws-edit-quantity-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 20vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:nth-child(4) {
                width: auto !important;
                min-width: 13vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 12vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.gws-tooltip {
    z-index: 999999 !important;
}

.no-data-cloud-invoice {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.gws-edit-quantity-table-order-confirmation {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 23vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }
            >div:nth-child(2) {
                width: auto !important;
                min-width: 12vw;
            }
            >div:nth-child(4) {
                width: auto !important;
                min-width: 16vw;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.edit-quantity-width {
    width: 90vw !important;
    max-height: 70vh !important;
    overflow-y: auto;
}
.gws-subsription-details-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 21vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

@media screen and (max-width:993px){
    .gws-edit-quantity-table-order-confirmation {
        div {
            &[role=row] {
                >div:first-child{
                    min-width: 17vw;
                }
                >div:nth-child(2) {
                    min-width: 15vw;
                }
                >div:nth-child(4) {
                    min-width: 20vw !important;
                }
    
                >div:last-child {
                    min-width: 15vw;
                }
    
            }
        }
    }
    
}
@media only screen and (min-width: 992px) and (max-width: 1024px){
    .gws-edit-quantity-table-order-confirmation {
        div {
            &[role=row] {
                >div:first-child{
                    min-width: 17vw;
                }
                >div:nth-child(2) {
                    min-width: 15vw;
                }
                >div:nth-child(4) {
                    min-width: 20vw !important;
                }
    
                >div:last-child {
                    min-width: 15vw;
                }
    
            }
        }
    }
}