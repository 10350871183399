.password-toggle {
    .form-control {
      border-radius: 6px !important;
        

        +.input-group-text {
            border-left: 0px;
            cursor: pointer;
            position: absolute;
            right: 0.75rem;
            top: 50%;
            transform: translateY(-50%);
            width: 30px;
            padding: 0;
            border: 0;
            background: transparent;
            z-index: 6;

           
        }
    }
}