@use "../../../../../../../Assets/Styles/variables";
.status-customers {
    text-align: center;
}

.customer-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 18vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 11vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            >div[data-column-id="Customer ID"] {
                min-width: 15vw;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.user-management-table-inner {
    &.table-details {
        min-height: 23rem;
    }
}

.no-data-customers {
    min-height: 54vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 57rem;
        }
    }
}

//ipad mini and ipad air
@media only screen and (max-width: 992px) and (min-width: 768px) {
    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 57rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .customer-table {
        div {
            &[role=row] {
                >div[data-column-id="Organization"] {
                    min-width: 30vw;
                }

                >div[data-column-id="Change Status"] {
                    min-width: 20vw;
                }
            }
        }
    }
}