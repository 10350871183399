@use '../../../../../../../Assets/Styles/variables';
.accordion-header-wrapper {
    display: flex;
    cursor: pointer;

    .accordion-number {
        border: 1px solid variables.$primary-color;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        line-height: 1.5rem;
        border-radius: 1.5rem;
        font-size: 0.875rem;
        color: variables.$stepper-number-background;
    }

    .accordion-name {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 500;
    }

    label {
        span {
            display: block;
        }
    }
}

.accordion-content {
    padding-left: 2rem;
}

.click-here {
    color: variables.$primary-color;
    text-decoration: underline;
}

.no-data-cloud-summary {
    min-height: 35vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}