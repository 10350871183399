@use '../../../../../Assets/Styles/variables';
.pContainer{
    width: 37.5rem;
    color: variables.$text-color-light;
    font-size: 1.25rem;
    white-space: pre-line;
    text-align: justify;
    margin-top: 0.75rem;
}
.cloud-h1{
    font-weight:600;
    font-size: 3rem;
    color: variables.$text-color-light;
       
    display: flex;
   
}
.welcome-h1{
    font-size: 3rem;
    font-weight: 350;
    color: variables.$text-color-light;
    text-align: left;
    
}


@media screen and (max-width: 1024px) {

    .cloud-h1, .welcome-h1 {
        font-size: 2rem; 
       margin-bottom: 0.5rem;
    }

    .pContainer {
        font-size: 1.2rem; 
        width: 40rem;
    }
}

@media screen and (max-width: 767px) {
    .content{
        // padding-left:2rem;
       }
    .cloud-h1, .welcome-h1 {
        font-size: 1.4rem;  
    }
    .cloud-h1{
        padding: 0.5rem 0rem !important;
    }

    .pContainer {
        font-size: 0.75rem; 
        width: 20rem;
    }
    .info-main-div{
        justify-content: flex-start !important;
    }
}