@use "../../Assets/Styles/variables";
.eye {
    background-image: url(../../Assets/Images/Icons/eye.svg);
    background-repeat: no-repeat;
    width: 30px;
    height: 16px;
    background-position: center;
    text-align: center;
    line-height: 14px;
    color: variables.$icons-secondary;
    font-size: 0px;
    transform: scale(0.9);

    &.hide-eye {
        background-image: url(../../Assets/Images/Icons/eyeHide.svg);
        background-repeat: no-repeat;
        font-size: 30px;
        font-weight: 100;
    }

    &.btn-eye {
        cursor: pointer;
    }   
}

.password-toggle {
    input {
        color: variables.$primary-neutral-text-color !important;

        &:focus-visible {
            outline: none;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .eye {
        transform: scale(0.8);
    }
}

@media only screen and (max-width: 767px) {
    .eye {
        transform: scale(0.7);
    }
}