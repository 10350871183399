@use '../../../Assets/Styles/variables';
.notification-div{
    background-color: variables.$text-color-light;
}
.notifications-no-data{
    background-color: variables.$text-color-light;
    min-height: 70vh;
    border-radius: 0.7rem;
    margin: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
        height: 7rem;
        width: auto;
        margin-bottom: 1rem;
    }
}