@use '../../../../../Assets/Styles/variables';

.cloud-summery-details-table {
    .details-box {
        border-radius: 0.8rem;
        background: variables.$text-color-light;
        padding: 0;
        margin: 1rem 0;
    }

    .cloud-summary-color-underline {
        color: variables.$primary-color;
        text-decoration: underline;
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 400;
    }

    .summary-detail-first-table {
        div {
            &[role=row] {

                >div:first-child {
                    // width: auto !important;
                    min-width: 12vw !important;
                    flex: 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                >div:last-child {
                    // width: auto !important;
                    min-width: 12vw !important;
                    flex: 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                &:hover {
                    opacity: 1;
                    background: variables.$table-hover-color !important;
                }
            }
        }
    }

    div {
        &[role=row] {

            >div:first-child {
                width: auto !important;
                // min-width: auto !important;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                // min-width: auto !important;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }

    .cloud-summery-invoice {
        div [data-column-id="Link"] {
            min-width: 15vw !important;
        }

        div [data-column-id="Domain"] {
            min-width: 20vw !important;
        }

        div {
            &[role=row] {

                >div:first-child {

                    min-width: 5vw !important;
                    flex: 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                >div:last-child {
                    width: auto !important;
                    min-width: 10vw !important;
                    flex: 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    justify-content: end;
                }
            }
        }

        div[role=columnheader] {
            white-space: nowrap;
        }
    }

    .btn-wrapper {
        text-align: right;
    }
}

.no-data-cloud-summary-details {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 1200px) {
    .cloud-summery-details-table .cloud-summery-invoice div[role=row]>div:last-child {
        flex: 1 0 !important;
    }

    .cloud-summery-details-table {
        .summary-detail-first-table {
            div {
                &[role=row] {

                    >div:first-child {
                        // width: auto !important;
                        min-width: 14vw !important;
                        flex: 0 0 !important;
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 993px) {
    .cloud-summery-details-table .cloud-summery-invoice div[role=row]>div:last-child {
        flex: 1 0 !important;
    }
}

@media screen and (max-width: 767px) {
    .cloud-summery-details-table {

        div {
            &[role=row] {

                >div:first-child {
                    min-width: 25vw !important;
                    // min-width: auto !important;
                    flex: 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }

                >div:last-child {
                    // width: auto !important;
                    min-width: auto !important;
                    flex: 1 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }

        .summary-detail-first-table {
            div {
                &[role=row] {

                    >div:first-child {
                        min-width: 30vw !important;
                        // min-width: auto !important;
                        flex: 0 0 !important;
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }

                    >div:last-child {
                        // width: auto !important;
                        min-width: 12vw !important;
                        flex: 1 0 0 !important;
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                }
            }
        }

        .btn-wrapper {
            text-align: left;
        }

        .mobile-table {
            float: left !important;
            margin-top: 2rem;

            td {
                text-align: left !important;
            }
        }

        .cloud-summery-invoice {
            overflow: auto;

            div {
                &[role=row] {
                    >div:first-child {
                        min-width: 20vw !important;
                    }

                    >div:last-child {
                        width: auto !important;
                        min-width: 20vw !important;
                    }
                }
            }
        }
    }
}