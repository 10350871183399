@use '../../../../../../Assets/Styles/variables';
.widget-body{
    border-radius: 0.75rem;
    background-color: variables.$text-color-light;
    padding: 0.5rem;
}
.widget-body-green{
    color: #4E8F37;
}
.widget-body-default{
    color: variables.$text-color;
    span{
        // transform: scale(0.8);
        margin-bottom: 0.15rem;
    }
}
.widget-body-yellow{
    color: #F9A932;
}
.sub-description{
    color: variables.$secondary-neutral-color;
}
.active-customer-padding-top{
    padding-top: 0.25rem;
}

@media only screen and (max-width: 767px) {
    .widget-body {
        margin-bottom: 1rem;
       }
}