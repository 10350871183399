@use '../../../../../../../Assets/Styles/variables';
.widgets {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: baseline;
    margin: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid variables.$primary-border-color;
    border-radius: 0.5rem;
}

.widgets-icon-background {
    background-color: #E9F5FD;
    border-radius: 50%;
}

.no-data-customers {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}