@use '.../../../../../Assets/Styles/variables';
.rdt_Pagination {
    width: 100% !important;
    flex-wrap: nowrap !important;
    display: block !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;

    >* {
        font-size: 0.875rem;
        color: variables.$primary-neutral-text-color;
    }

    >div,
    >span {
        display: inline-block;
        padding: 1rem 0 0;
        margin-left: 0px;

        >select {
            &+svg {
                top: 16px;
            }
        }
    }

    div:last-child {
        float: right !important;
        display: flex !important;
        width: 50% !important;
        padding-top: 0.6rem;
        justify-content: flex-end;
    }
}

.table {
    &:hover {
        background-color: variables.$text-color-light;
    }

    thead {
        tr {
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            th {
                min-height: 52px;
                box-sizing: border-box;
                line-height: normal;
                padding: 1rem;
                background-color: rgb(250, 250, 250);
                font-weight: 500;
                font-size: 0.875rem;
                text-transform: capitalize;
                color: rgb(106, 106, 106) !important;

                span {
                    font-size: 0.875rem;
                    color: rgb(106, 106, 106) !important;
                    white-space: nowrap;
                }

                &:first-child {
                    width: auto;
                    padding: 1rem;
                    min-width: 3rem;
                }

                &:last-child {
                    width: auto;
                    padding: 1rem;
                    min-width: 3rem;
                }
            }
        }
    }

    tbody {


        tr {
            box-sizing: border-box;
            font-size: 0.875rem;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.87);
            background-color: rgb(255, 255, 255);
            min-height: 60px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            &.no-bdr-row {
                border-bottom: 0;
            }

            &.no-hover-row {
                border-bottom: 0;

                &:hover {
                    td {
                        background-color: transparent !important;
                    }
                }
            }

            &:hover {
                background: transparent;
                background-color: none;

                td {
                    background-color: variables.$table-hover-color !important;
                }
            }

            td {
                min-height: 52px;
                box-sizing: border-box;
                line-height: normal;
                padding: 1rem;
                font-weight: normal;
                font-size: 0.875rem;
                // text-transform: capitalize;
                color: variables.$primary-neutral-text-color;
                border-bottom: 0;

                &:first-child {
                    width: auto;
                    padding: 1rem;
                    min-width: 3rem;
                }

                &:last-child {
                    width: auto;
                    padding: 1rem;
                    min-width: 3rem;
                }
            }
        }
    }
}

div {
    &[role=row] {

        >div:first-child {
            width: auto !important;
            min-width: 18vw;
            flex: 0 0 !important;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        >div:last-child {
            width: auto !important;
            min-width: 14vw;
            flex: 0 0 !important;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;
        }
    }
}

.table-responsive {
    .dropstart {
        .dropdown-menu {
            .dropdown-item {
                &:hover {
                    background: #3599DA;
                    color: variables.$text-color-light;
                }
            }
        }
    }
}

.mini-table {
    width: auto;

    .details-box {
        width: auto;

        .table-content {
            width: auto !important;
        }
    }
}

@media screen and (max-width: 600px) {
    .mini-table {
        width: 100%;

        .details-box {
            width: 100%;

            .table-content {
                width: 100% !important;
            }
        }
    }
}

// Table Column Width Based on Column Name

div {
    &[role=row] {
        >div[data-column-id="Sub-Total Price"] {
            min-width: 12rem;
        }

        >div[data-column-id="Price (Ex GST)"] {
            min-width: 12rem;
        }

        >div[data-column-id="Current Quantity"] {
            min-width: 12rem;
        }
    }
}

@media screen and (max-width: 1023px) {
    thead>tr>th {
        white-space: nowrap;
        text-wrap: nowrap;
    }
}


.failed_row {
    border-left: 3px solid variables.$primary-danger;
    border-radius: 4px 0 0 4px;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
    .csp-gws-actions-table {
        div {
            > div[data-column-id="Plan"] {
                width: auto !important;
                min-width: 20rem;
            }
        }        
    }
}