
@use '../../../../../../../Assets/Styles/variables';
.order-history-color-underline {
    color: variables.$primary-color;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 400;
}

div {
    &[role=columnheader] {
        >div {
            color: variables.$secondary-neutral-color;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        &:hover {
            opacity: 1;

            >div {
                color: variables.$secondary-neutral-color;
                font-size: 0.875rem;
                font-weight: 500;


            }
        }
    }
}

div {
    &[role=cell] {
        div {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;

            div {
                color: variables.$primary-neutral-text-color;
                font-size: 0.875rem;
            }
        }
    }
}

.order-history-history-table {
    div {
        &[role=row] {

            >div:first-child {
                width: auto !important;
                min-width: 25vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            >div[data-column-id="Cloud Service"] {
                min-width: 10rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
                display: flex;
                justify-content: center;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}


.loading-order-history {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-data-order-history {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.status-orderhistory {
    text-align: center;
}
.gdap-height{
    height: 25rem;
}
.order-history-table-inner {
    &.table-details {
        min-height: 20rem;
    }
}


@media screen and (max-width: 993px) {
    .height-roles{
        width:25rem !important;
    }

    .order-history-history-table {

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 10rem;
            word-break: initial;
        }
    }

    .custom-modal-order-history-content {
        max-width: 500px !important;
        min-width: 300px !important;
        width: auto;
    }
}

@media screen and (max-width: 767px) {

    .height-roles{
        width:20rem !important;
    }
    .custom-modal-order-history-content {
        max-width: 90% !important;
        min-width: 200px !important;
        width: auto;
    }

    .order-history-table {
        overflow: hidden;
    }

    .order-history-history-table {

        div[role=table]>div[role=rowgroup]> {
            div[role=row]>div {
                min-width: 12rem;
            }
        }

        div[role=columnheader]>div {
            display: flex;

            .sort-icon-desc,
            .sort-icon-asc {
                background-size: 100% !important;
            }
        }

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 20rem;
            word-break: initial;
        }
    }
}


.order-history-message {
    white-space: normal;
    word-break: break-all;
    max-height: 10vh;
    overflow-y: auto;
}

.ticket-table-additional-information-popup-loading {
    min-height: 45vh;
    display: flex;
    align-items: center;
    justify-content: center;
    p {
        font-size: 1.2rem;
        font-weight: 500;
    }
}
.ticket-table-additional-information-popup-loading-row{
    div{
        p *{
            word-break: normal !important;
        }
        
    }
    
}
// ------------------------------------------
.table-height-adjustment{
    height: 22rem !important;
    overflow: auto;
}
.table-alignment{
    tr{vertical-align: middle;}
    
}

.expandable-view-roles-gdap{
    min-height: 3rem !important;
}
.height-displayName{
    width:20rem !important;
}
.height-startDate{
    width: 10rem !important;
}
.height-roles{
    width:12rem ;
}
.height-status{
    width: 15rem !important;
}