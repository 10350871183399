@use '../../Assets/Styles/variables';
// Number Type

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
}

.number-input {
    border: 1px solid variables.$primary-border-color;
    display: inline-flex;
    border-radius: 6px;
    overflow: hidden;

    &:focus {
        border: 1px solid variables.$primary-color;
    }

    .btn-wrapper {
        width: 1.5rem;
        height: 2.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        border-left: 1px solid variables.$primary-border-color;
        align-items: center;
        border-radius: 0 6px 6px 0;
        margin-left: -1.5rem;
        overflow: hidden;
        background: variables.$text-color-light;

        .plus {
            border-bottom: 1px solid variables.$primary-border-color !important;

            &.disabled {
                border-radius: 0 6px 0 0;
            }
        }

        .minus {
            span {
                width: 0.5rem;
                height: 2px;
                background: variables.$secondary-neutral-color;
            }

            &.disabled {
                border-radius: 0 0px 6px 0;
            }
        }
    }

    button {
        outline: none;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.25rem;
        cursor: pointer;
        margin: 0;
        position: relative;
        display: flex;
        line-height: initial;
        padding: 0;
        font-size: 1.125rem;
        color: variables.$secondary-neutral-color;


        &.disabled {
            background-color: variables.$button-disabled-background;
            cursor: not-allowed;
        }
    }
}

.number-input,
.number-input * {
    box-sizing: border-box;
    width: 5rem;

    input {
        border-right: 0px !important;
        border: 0px;
        margin-right: 1.5rem;
        flex-grow: 2;
        width: calc(100% - 1.5rem);
        text-align: center;

        &:focus {
            outline: none;
        }
    }
}

.min-max-wrapper {
    margin-right: 2.9rem;
}