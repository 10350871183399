@use '../../../../Assets/Styles/variables';
.unbilled-usage-table {
    padding-left: 2rem;
    padding-right: 2rem;
}

.unbilled-usage-color-underline {
    color: variables.$primary-color;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 400;
}

div {
    &[role=columnheader] {
        >div {
            color: variables.$secondary-neutral-color;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        &:hover {
            opacity: 1;

            >div {
                color: variables.$secondary-neutral-color;
                font-size: 0.875rem;
                font-weight: 500;


            }
        }
    }
}

div {
    &[role=cell] {
        div {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;

            div {
                color: variables.$primary-neutral-text-color;
                font-size: 0.875rem;
            }
        }
    }
}

div {
    &[data-column-id="Active Billing Cycle"] {
        min-width: 190px;
        max-width: inherit;
    }
}

.unbilled-usage-table-inner {
    div {
        &[role=row] {

            >div:first-child {
                width: auto !important;
                min-width: 6vw;
                flex: 0 0 !important;
                padding-left: 0rem;
                padding-right: 0rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 9vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}



// .unbilled-usage-history-table{
//     min-height: 50vh;
// }

.loading-unbilled-usage {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-data-unbilled-usage {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

// .ticket-table-additional-information-popup-loading {
//     min-height: 45vh;
//     display: flex;
//     align-items: center;
//     justify-content: center;

//     p {
//         font-size: 1.2rem;
//         font-weight: 500;
//     }
// }


.unbilled-usage-table-inner {
    &.table-details {
        min-height: 20rem;
    }
}

// .ticket-message {
//     white-space: normal;
//     word-break: break-all;
//     max-height: 20vh;
//     overflow-y: auto;
// }

@media screen and (max-width: 993px) {
    .unbilled-usage-history-table {

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 10rem;
            word-break: initial;
        }
    }

    .custom-modal-unbilled-usage-content {
        max-width: 500px !important;
        min-width: 300px !important;
        width: auto;
    }
}

@media screen and (max-width: 767px) {

    // .create-ticket-btn {
    //     position: fixed;
    //     right: 2rem;
    //     top: 48rem;
    //     padding: 1.5rem 3rem;
    //     line-height: 1;
    //     height: auto;
    //     max-width: initial;
    //     width: auto;
    //     min-width: 10rem;
    //     font-size: 1rem;
    //     transform: scale(1.2);
    //     z-index: 9999;
    // }

    .custom-modal-unbilled-usage-content {
        max-width: 90% !important;
        min-width: 200px !important;
        width: auto;
    }

    .unbilled-usage-table {
        padding: 0px !important;
        overflow: hidden;
    }

    .unbilled-usage-history-table {

        div[role=table]>div[role=rowgroup]> {
            div[role=row]>div {
                min-width: 12rem;
            }
        }

        div[role=columnheader]>div {
            display: flex;

            .sort-icon-desc,
            .sort-icon-asc {
                background-size: 100% !important;
            }
        }

        div[role=row]>div:first-child,
        div[role=row]>div:last-child {
            min-width: 10rem;
            word-break: initial;
        }
    }
}