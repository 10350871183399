@use "../../../../../Assets/Styles/variables";
.no-data-cloud-invoice {
    min-height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.no-data-cloud-delete{
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.table-height-adjustment{
    max-height: 14rem !important;
    overflow: auto;
}

.tags-product-listing-trial {
    background-color: #4BEE844D !important;
    // opacity: 50%!important;
    color: variables.$primary-neutral-text-color !important;
    border: none !important;
    padding: 0.5rem !important;
    border-radius: 0.375rem!important;
    font-size: 0.8rem !important;
    height: 2rem !important;
    &:hover {
        opacity: 0.6 !important;
    }
}

.tags-product-listing-expired {
    background-color: rgb(252, 183, 183) !important;
    // opacity: 50%!important;
    color: variables.$primary-neutral-text-color !important;
    border: none !important;
    padding: 0.5rem !important;
    border-radius: 0.375rem!important;
    font-size: 0.8rem !important;
    height: 2rem !important;
    &:hover {
        opacity: 0.6 !important;
    }
}

@media screen and (max-width: 767px) {
    .mobile-width{
        min-width: 25rem !important;
    }
    .mobile-display{
        display: none;
    }
   
}