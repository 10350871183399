@use '../../../../../Assets/Styles/variables';
.widget-body{
    border-radius: 0.75rem;
    background-color: variables.$text-color-light;
    padding: 0.5rem;
}
.widget-body-green{
    color: #4E8F37;
}
.widget-body-default{
    color: variables.$text-color;
}
.widget-body-yellow{
    color: #F9A932;
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .widget-body {
        margin-bottom: 1rem;
    }
}
@media only screen and (max-width: 767px) {
    .widget-body {
        margin-bottom: 1rem;
    }
}