@use '../../../../../Assets/Styles/variables';
.layout {
  height: 10vh;
  width: 100%;

  div {
    border-radius: 0.75rem;
    background-color: variables.$text-color-light;
    padding: 0.5rem;
  }
}

.widget-header {
  border: none !important;
  width: 100%;
}

.widget-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recent-customers {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.1rem;
}


.widget-header {
  border: none !important;
  width: 100%;
}

.widget-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 18rem;
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 1rem;
  margin-bottom: 1rem;
}

.widget-body-no-flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 18rem;
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 1rem;
  margin-bottom: 1rem;
}

.recent-customers {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.1rem;
}

.monthly-summary-box {
  background-color: variables.$widget-background-secondary;
  margin: 0.5rem;
  border-radius: 0.75rem;

  span {
    color: variables.$secondary-neutral-color !important;
  }
}

.monthly-summary {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.8rem;
}

.left-recent-customers {
  width: 60%;
}

.quick-actions-item {
  color: variables.$primary-color;
}

.customer-profile {
  background-color: variables.$table-row-hover;
  border-radius: 50%;
  color: variables.$primary-color;
  padding: 1.5rem 1.3rem;
  font-weight: 500;
  font-size: 1.125rem;
}

.customer-profile-outer {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .left-recent-customers {
    width: 100% !important;
  }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .left-widgets-ipad-pro {
    width: 35%;
  }

  .right-widgets-ipad-pro {
    width: 65%;
  }

  .individual-widgets-ipad-pro {
    width: 100%;
  }

  .top-heading-ipad-pro {
    width: 45%;
  }
}