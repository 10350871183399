@use '../../../../../../Assets/Styles/variables';
.no-data-cloud-invoice {
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.productlisting-main-table{
    border-top: 2px solid variables.$primary-border-color;
}
.big-width{
    max-width: 10rem;
}
.small-width{
    max-width: 2rem ;
}
.csp-change-status-core-table{
    tbody tr td:first-child {
        width: 0.1rem !important;
        // padding: 0;
    }
   
}
.GCP-plan-selected{
    padding: 0 !important;
}
.csp-purchase-addon-count-row-inner{
    padding: 1rem 1rem !important;
}
.empty-selected-data{
    height: 4.5rem !important;
}
.vertical-align-middle{
    align-items: center;
}

@media screen and (max-width: 767px) {

    .csp-change-status-core-table{
        tbody tr td:first-child {
            display: none;
        }
        thead tr th:first-child {
            display: none;
        }
    }

}