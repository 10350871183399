@use '../../../../../../Assets/Styles/variables';
.current-subscription-tag{
    font-size: 0.75rem;
    font-weight: 500;
    color: variables.$primary-color;
}
.Avast-top-heading{
    background-color:variables.$background-secondary-color;
}
.productlisting-main-table {
    border-top: 2px solid variables.$primary-border-color;
}
.big-width{
    max-width: 10rem;
}
.csp-change-status-core-table-1{
    tbody tr td:first-child {
        width: 1rem !important;
        // padding: 0;
    }  
    td:nth-child(3) {
        width: 20rem !important;
    }
    td:nth-child(1) {
        width: 20rem !important;
    }
    td:nth-child(5) {
        width: 1rem !important;
    }

    
}
.delete-column {
    width: 6rem !important;
}
.vertical-align-middle{
    vertical-align: middle;
}