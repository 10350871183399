@use '../../../Assets/Styles/variables';
.cookie-popup {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: variables.$text-color-light;;
    padding: 1rem;
    border: 1px solid #ddd;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 999999;
  }
  
  .cookie-popup h2 {
    margin-top: 0;
  }
  
  .cookie-popup p {
    margin-bottom: 20px;
  }
  
  .cookie-popup button {
    margin-right: 10px;
  }