@use '../../../../../Assets/Styles/variables';
.dynamic-options-block {
    max-height: 40vh;
    height: auto;
    overflow: auto;
    overflow-x: hidden;
}
.create-new-customer-dropdown-provision{
    border-top: 1px solid variables.$primary-border-color;
}
.provision-border-width{
    border: 1px solid variables.$primary-danger;
}