@use '../../../../../../../../../Assets/Styles/variables';
.status-customers {
    text-align: center;
}

.customer-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 10vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 7vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            >div[data-column-id="Customer"] {
                min-width: 15vw;
            }

            >div[data-column-id="Balance Amount"] {
                min-width: 10vw;
            }

            >div[data-column-id="Amount"] {
                min-width: 5vw;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.no-data-customers {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bar-chart-height {
    height: 65vh;
}

.dropdown-disabled {
    background-color: variables.$text-color-light !important;
}

.user-management-table-inner {
    &.table-details {
        min-height: 23rem;
    }
}

.status-cloud-invoice {
    text-align: center;
}


@media only screen and (max-width: 767px) {
    .customer-table {
        div {
            &[role=row] {
                >div[data-column-id="Organization"] {
                    min-width: 30vw;
                }

                >div[data-column-id="Change status"] {
                    min-width: 20vw;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px){
    .bar-chart-height {
        max-height: 85vh;
        scroll-behavior: hidden;
    }

    .search-tablet-view {
        align-items: baseline !important;
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 58rem;
        }
    }
    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 17vw;
                    flex: 0 0 !important;
                    padding-left: 2rem;
                    padding-right: 1rem;
                }
                >div[data-column-id="Balance Amount"] {
                    min-width: 15vw !important;
                }
                >div[data-column-id="Status"] {
                    min-width: 10vw !important;
                }
                >div[data-column-id="Amount"] {
                    min-width: 10vw !important;
                }
            }
        }
    }
  }
   //ipad-pro
   @media only screen and (min-width: 992px) and (max-width: 1024px) {
    .bar-chart-height {
        max-height: 85vh;
        scroll-behavior: hidden;
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 58rem;
        }
    }
    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 17vw;
                    flex: 0 0 !important;
                    padding-left: 2rem;
                    padding-right: 1rem;
                }
                >div[data-column-id="Balance Amount"] {
                    min-width: 15vw !important;
                }
                >div[data-column-id="Summary No."] {
                    min-width: 15vw !important;
                }
                >div[data-column-id="Invoice Date"] {
                    min-width: 15vw !important;
                }
                >div[data-column-id="Due Date"] {
                    min-width: 15vw !important;
                }
                >div[data-column-id="Status"] {
                    min-width: 10vw !important;
                }
                >div[data-column-id="Amount"] {
                    min-width: 10vw !important;
                }
            }
        }
    }
}