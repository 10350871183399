@use '../../../../../../../Assets/Styles/variables';
.dropdown-disabled {
    background-color: variables.$text-color-light !important;
}

.user-management-table-inner {
    &.table-details {
        min-height: 20rem;
    }
}

.customer-table {
    div[role=columnheader]>div {
        background-color: #F6F6F6;
    }

    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 6vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 20vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

        }
    }
}

.no-data-customers {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.numbers {
    width: 1.3rem;
    line-height: 1.2rem;
    border-radius: 50%;
    text-align: center;
    border: 1px variables.$primary-color solid;
    background-color: transparent;
    color: variables.$primary-color;
    font-weight: 500;
    font-size: 0.75rem;
}

@media only screen and (max-width: 767px) {
    .customer-table {
        div {
            &[role=row] {
                >div[data-column-id="Subscriptions"] {
                    min-width: 15vw;
                }
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 10vw;
                }
            }
        }
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 10vw;
                }
            }
        }
    }

    .no-data-customers {
        min-height: 30vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .customer-table {
        max-height: 30rem !important;
        overflow: hidden;
    }
}