@use '../../../../../../../Assets/Styles/variables';
.layout {
  height: 10vh;
  width: 100%;

  div {
    border-radius: 0.75rem;
    background-color: variables.$text-color-light;
    padding: 0.5rem;
  }
}

.widget-header {
  border: none !important;
  width: 100%;
}

.widget-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.recent-customers {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.1rem;
}

.monthly-summary-box {
  background-color: variables.$widget-background-secondary;
  margin: 0.5rem;
  border-radius: 0.75rem;

  span {
    color: variables.$secondary-neutral-color !important;
  }
}

.monthly-summary {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.8rem;
  margin-top: 1rem;
}

.dropdown-disabled {
  background-color: variables.$text-color-light !important;
}