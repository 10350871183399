@use '../../../Assets/Styles/variables';
.product-plan-outer-card {
    background-color: variables.$text-color-light;
    padding: 1rem 0rem;
    border-radius: 0.75rem;
    display: flex;
    align-content: center;
    align-items: center;

    span.card-product-name {
        font-size: 1.125rem;
        font-weight: 500;
        margin-right: 1rem;
    }

    button {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

.no-data-cloud-delete{
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.vendor-requirement-plan-outer-card{
    background-color: variables.$table-row-hover;
    padding: 1rem 0.5rem;
    border-radius: 0.75rem;
    display: flex;
    align-content: center;
    align-items: center;
    h3{
        font-weight: 500;
        margin: 0;
    }
    p{
        font-size: 0.75rem;
    }
}

.vendor-requirement-plan-outer-card-expiry{
    background-color: variables.$primary-danger-hover;
    padding: 1rem 0.5rem;
    border-radius: 0.75rem;
    display: flex;
    align-content: center;
    align-items: center;
    h3{
        font-weight: 500;
        margin: 0;
    }
    p{
        font-size: 0.75rem;
    }
}

.vendor-requirement-plan-outer-card-heading{
    display: flex;
    justify-content: center;
    // min-height: 5rem;
    flex-direction: column;
}

.vendor-requirement-plan-outer-card-button{
    button{
        padding: 0.5rem;
    }
}

.tags-product-listing-featured {
    background-color: #747CED80 !important;
    color: variables.$primary-neutral-text-color !important;
    // opacity: 50%!important;
    border: none !important;
    padding: 0.5rem !important;
    border-radius: 0.375rem!important;
    font-size: 0.8rem !important;
    cursor: context-menu !important;
}

.tags-product-listing-trial {
    background-color: #5fcc9b37 !important;
    // opacity: 50%!important;
    color: variables.$primary-neutral-text-color !important;
    border: none !important;
    padding: 0.5rem !important;
    border-radius: 0.375rem!important;
    font-size: 0.8rem !important;
    cursor: context-menu !important;

    // &:hover {
    //     opacity: 0.6 !important;
    // }
}

.product-listing-right-navbar {
    background-color: variables.$text-color-light !important;
    // border-top: 1px solid variables.$primary-border-color;
    // border-right: 1px solid variables.$primary-border-color; 
    // height: calc(100vh - 100px); 
     min-height: 85vh; 
     border-top-right-radius: 0.75rem;   
     position: sticky;
     top: 0;
     z-index: 1;
}

.product-plan-top-right-card-outer {
    background-color: variables.$text-color-light !important;
    padding: 1rem 0rem;
    border-radius: 0.75rem;
}

.product-plan-top-right-card {
    > div {
        display: flex;
        justify-content: center;
    }
}

.img-bg {
    background: variables.$background-secondary-color;
    padding: 1rem;
    height: 3.2rem;
    width: auto ;
}

.card-product-name{
    font-weight: 400 !important;
    font-size: 1rem!important;
}

.current-subscription-tag{
    font-size: 0.75rem;
    font-weight: 500;
    color: variables.$primary-color;
}

.main-loading-component{
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.check-domain-link{
    color: variables.$primary-color;
    text-decoration-color: variables.$primary-color;
    font-weight: 500;
}
.check-domain-error{
    color: variables.$primary-danger;
}

.active-main{
    font-weight: 500;
}
.active-service{
    background-color: variables.$widget-background-secondary;
    
    border-radius: 20px;
    padding: 0.6rem 2rem;
    font-weight: 500;
    *{
        font-weight: 500;
    }
}
.inactive-service{
    border-radius: 20px;
    padding: 0.6rem 2rem;
}
.active-product{
    background-color: #EEEEEE;
    border-radius: 20px;
    padding: 0.4rem 2rem;
    font-weight: 500;
    *{
        font-weight: 500;
    }
}
.inactive-product{
    border-radius: 20px;
    padding: 0.4rem 2rem;
}


@media screen and (max-width: 767px) {
    .product-listing-right-navbar {
        height: auto;
    }
    .product-listing-right-navbar{
        min-height: 30vh; 
    }
    .trail-featured-button{
        display:none;
    }
}

.floating-container {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: variables.$text-color-light;
  box-shadow: 10px 10px variables.$background-secondary-color;
  border-radius: 0.75rem;
  width: 97%;
}

@media screen and (min-width: 1023px) and (max-width: 1025px) {
    .inactive-service {
        padding-right: 0.75rem;
    }
}