@use "../../../../../Assets/Styles/variables";
.microsoft-azure-table {

    tr {

        td:first-child,
        th:first-child {
            min-width: 1rem !important;
            width: 1rem !important;
        }

        td:nth-child(2) {
            width: 15rem;
        }

        td:nth-child(3) {
            width: 25rem;
        }

        td:nth-child(4) {
            width: auto;
        }

        td:nth-child(5) {
            width: auto;
        }

        td:nth-child(6) {
            width: auto;
        }

        td:last-child {
            width: auto;
        }
    }
}

.cloud-invocies-details-table {

    .hasSNo {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 5rem;
                    flex: 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 1rem;
                }
            }
        }
    }
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 13vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            >div[data-column-id="Plan Name and Description"] {
                min-width: 25rem;
                flex: 2;
                text-align: left;
                display: flex;
                align-content: left;
                flex-grow: 0 0 2;
            }

            >div[data-column-id="Qty"] {
                flex: 1 1;
                width: auto;
                min-width: 5rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 10rem;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 1rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }

    .has-serial-number {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 5rem;
                }
            }
        }
    }

    .table {
        thead {
            tr {
                th {

                    &:first-child {
                        width: auto;
                        padding: 1rem;
                        min-width: 3rem;
                    }

                    &:last-child {
                        width: auto;
                        padding: 1rem;
                        min-width: 3rem;
                    }
                }


            }
        }

        tbody {
            tr {
                td {
                    &:first-child {
                        width: auto;
                        padding: 1rem;
                        min-width: 3rem;
                    }

                    &:last-child {
                        width: auto;
                        padding: 1rem;
                        min-width: 3rem;
                    }
                }
            }
        }
    }

    .btn-wrapper {
        text-align: right;
    }
}

.no-data-cloud-invoice-details {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 993px) {
    .cloud-invocies-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 16vw;
                }

                >div:last-child {
                    width: auto !important;
                    min-width: 15vw;
                }

                &:hover {
                    opacity: 1;
                    background: variables.$table-hover-color !important;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .cloud-invocies-details-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 30vw;
                }

                >div:last-child {
                    width: auto !important;
                    min-width: 30vw;
                }

                &:hover {
                    opacity: 1;
                    background: variables.$table-hover-color !important;
                }
            }
        }

        .btn-wrapper {
            text-align: left;
            margin-top: 1rem;
        }
        
        .mobile-table{
            float: left !important;
            margin-top: 2rem;
            td{
                text-align: left !important;
            }
        }
    }
}