@use '../../Assets/Styles/variables';
.notification-body-main{
    max-height: 75vh;
    width: 37rem;
    padding: 1rem;
}
.notifications-body{
    max-height: 58vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 1rem;
}
.notification-centre-description{
    // overflow: hidden;

    p {
        white-space: normal;
    }
}
.notification-created-at{
    color: variables.$secondary-neutral-color;
}

.notification-main-body-focus{
    background-color: variables.$table-row-hover;
    transition: ease-in-out 1s;
}

.notification-centre-no-data{
    height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    img{
        height: 5rem;
        width: auto;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 767px) {
    .notification-body-main {
        max-height: 80vh;
        width: calc(100vw - 2rem);
        padding: 1rem;
        position: fixed;
        left: 1rem;
        z-index: 9999;
        background: variables.$text-color-light;
        right: 1rem;
    }
}