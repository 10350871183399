@use '../../../../../../Assets/Styles/variables';
.accordion-header-wrapper {
    display: flex;
    cursor: pointer;



    .accordion-number {
        border: 1px solid variables.$primary-color;
        width: 1.5rem;
        height: 1.5rem;
        text-align: center;
        line-height: 1.5rem;
        border-radius: 1.5rem;
        font-size: 0.875rem;
        color: variables.$stepper-number-background;
    }

    .accordion-name {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 500;
    }

    label {
        span {
            display: block;
        }
    }
}

.accordion-content {
    padding-left: 2rem;
}

.accordion-content-has-table {
    padding-left: 0rem !important;
}

.success-panel {
    padding: 2rem 0;
    text-align: center;
    display: flex;
    align-items: center;
    height: 60vh;
}

.transition-domain-checks {
    display: block;

    .round-number {
        background: variables.$primary-border-color;
        color: variables.$primary-neutral-text-color;
        font-size: 0.875rem;
        font-weight: 500;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 1.25rem;
        text-align: center;
        line-height: 1.25rem;
        margin-right: 0.5rem;
    }
}

.gws-table-outer {
    overflow: visible;

    td {
        position: relative;
    }
}




.gws-prerequisite-table {
    th {
        padding: 1rem;
        background-color: rgb(250, 250, 250);
        font-weight: 500;
        font-size: 0.875rem;
        text-transform: capitalize;
        color: rgb(106, 106, 106) !important;
        vertical-align: middle;

        >span {
            background: transparent !important;
            background-color: none !important;
            white-space: nowrap;
            padding: 0 !important;
        }
    }

    td {
        padding: 1rem;
        vertical-align: middle;

        &:first-child,
        &:last-child {
            padding: 1rem !important;
        }

        .billing-term-td-dropdown {
            .dropdown-menu {
                top: 0px !important;
            }
        }
    }
}

.show-qty-btn-wrapper {
    border: 1px solid variables.$primary-border-color;
    display: inline-flex;
    border-radius: 6px;
    overflow: hidden;
    background: variables.$text-color-light;
    width: 5rem;
    align-items: center;

    &.disabled {
        border: 1px solid #e0e0e0;
        background: variables.$button-disabled-background;
        cursor: not-allowed;

        label {
            background: variables.$button-disabled-background;
            cursor: not-allowed;
        }

        .btn-wrapper {
            background: variables.$button-disabled-background;
            border-left: 1px solid #cdcdcd !important;
        }
    }

    label {
        border-right: 0px !important;
        border: 0px;
        margin-right: 24px;
        flex-grow: 2;
        width: calc(100% - 24px);
        text-align: center;
        font-size: 0.875rem !important;
        background: variables.$text-color-light;

        &:focus {
            outline: none;
        }
    }

    .btn-wrapper {
        width: 1.5rem;
        height: 2.5rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        border: 0px !important;
        border-left: 1px solid variables.$primary-border-color !important;
        align-items: center;
        border-radius: 0 6px 6px 0;
        margin-left: -1.5rem;
        border: 1px solid variables.$primary-border-color;
        overflow: hidden;
        background: variables.$text-color-light;

        .plus {
            border-bottom: 1px solid variables.$primary-border-color !important;

            &.disabled {
                border-radius: 0 6px 0 0;
            }
        }

        .minus {

            span {
                width: 0.5rem;
                height: 2px;
                background: variables.$secondary-neutral-color;
            }

            &.disabled {
                border-radius: 0 0px 6px 0;

                span {
                    width: 0.5rem;
                    height: 2px;
                    background: variables.$table-column-background-secondary;
                }
            }
        }
    }

    button {
        outline: none;
        -webkit-appearance: none;
        background-color: transparent;
        border: none;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 1.25rem;
        cursor: pointer;
        margin: 0;
        position: relative;
        display: flex;
        line-height: initial;
        padding: 0;
        font-size: 1.125rem;
        color: variables.$secondary-neutral-color;


        &.disabled {
            background-color: variables.$button-disabled-background;
            cursor: not-allowed;
        }
    }
}

.gws-tooltip {
    z-index: 999999 !important;
}
.no-data-cloud-delete{
    min-height: 30vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}