@use '../../../../../../../Assets/Styles/variables';
.status-customers {
    text-align: center;
}

.customer-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 14vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            >div[data-column-id="Credit Note Amount"] {
                min-width: 15vw;
            }

            >div[data-column-id="Invoice Amount"] {
                min-width: 12vw;
            }

            >div[data-column-id="Summary Date"] {
                min-width: 10vw;
            }

            >div[data-column-id="Cloud Service"] {
                min-width: 18vw;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.no-data-customers {
    min-height: 55vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bar-chart-height {
    height: 65vh;
}

.dropdown-disabled {
    background-color: variables.$text-color-light !important;
}

.user-management-table-inner {
    &.table-details {
        min-height: 23rem;
    }
}

.status-cloud-invoice {
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 57rem;
        }
    }

    .bar-chart-height {
        padding-top: 2rem;
        height: 45vh;
    }

    .search-tablet-view {
        align-items: baseline !important;
    }

    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 17vw;
                    flex: 0 0 !important;
                    padding-left: 2rem;
                    padding-right: 1rem;
                }

                >div[data-column-id="Balance Amount"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Status"] {
                    min-width: 10vw !important;
                }

                >div[data-column-id="Summary Date"] {
                    min-width: 15vw !important;
                }
            }
        }
    }
}

//ipad pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .bar-chart-height {
        padding-top: 2rem;
        height: 45vh;
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 57rem;
        }
    }
}