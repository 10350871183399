@use '../../../../../Assets/Styles/variables';
.carousel-container {
  text-align: center;
  padding: 1.75rem;
  color: variables.$text-color;
  padding-top: 2.25rem;
  padding-bottom: 3rem;
}

.carousel-header {
  text-align: center;
  margin-bottom: 1.25rem;
  position: relative;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  align-items: center;
  flex-wrap: wrap;
}


.h3 {
  color: #73a4df;
  font-weight: bold;
  width: 100%;
  margin-bottom: 1rem;
}

.h1 {
  color: #17446c;
  width: 80%;
  font-size: 2rem;
  padding-left: 0;

  span {
    font-size: 2rem;
    font-weight: 500;
    color: #17446c;
  }
}

.carousel-buttons {
  position: absolute;
  right: 5rem;
  top: 2.5rem;
  display: flex;
  gap: 0.625rem;
}

.carousel-button {
  background-color: #d0d5da;
  color: variables.$text-color-light;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  display: flex;
  justify-content: center; 
  align-items: center;

  &:hover:not(:disabled) {
    background-color: #0056b3;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.carousel-wrapper {
  position: relative;
  margin: 0;
}

.carousel-row {
  display: flex;
  justify-content: center;
  padding-left: 0rem;
  padding-right: 0rem;
}

.carousel-card {
  margin: 0.625rem;
  border: none;
  border-radius: 0.313rem;
  text-align: left;
  height: 100%;
  width: 100%;
  border: none;
  position: relative;
  // background-color: red;
}

.card-img-container {
  position: relative;
}

.card-img-container img {
  width: 100%;
  height: 12.5rem;
  object-fit: cover;
}

.card-title {
  position: absolute;
  top: 0.625rem;
  left: 0px;
  background: #0055b0a4;
  color: variables.$text-color-light;
  padding: 0.3rem 1.5rem;
  border-radius: 0 1.25rem 1.25rem 0;
  font-size: 1rem;
  font-weight: bold;
}

.card-body {
  position: relative;
  // height: 50rem;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  background-color: variables.$text-color-light !important;
  padding-top: 1.4rem !important;
  padding-bottom: 1.4rem !important;
}

.title {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
}

.carousel-desc {
  color: #2e2e2e;
  text-overflow: ellipsis;
  max-width: 90%;
  display: block;
  font-size: 0.875rem;
  margin-top: 0%;
  text-align: left;
  padding: 0.5rem 0rem;
  margin-bottom: 0;
  height: 100%;
}

.card-arrow {
  position: absolute;
  top: 40%;
  right: 0.5rem;
  font-size: 1.25rem;
  background-color: #0055b099;
  color: variables.$text-color-light;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center; 
  align-items: center; 
}
.chevron-right {
  color: variables.$text-color-light;
  align-items: center;
  text-align: center;
  align-content: center;
  stroke: variables.$text-color-light;
  stroke-width: 2;
  transform: translateX(0.1rem);
}

.chevron-left {
  color: variables.$text-color-light !important;
  stroke: variables.$text-color-light;
  stroke-width: 2;
}

.chevron-right {
  color: variables.$text-color-light !important;
}

.card-arrow:hover {
  background-color: #0055b0de;
}

@media (max-width: 1310px) {
  .h1 {
    width: 60%;
}
}

@media (max-width: 1024px) {
  .carousel-wrapper {
    position: relative;
    max-width: 70rem;
    margin: 0 auto;
  }

  .carousel-header {
    text-align: center;
    margin-bottom: 0.938rem;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .carousel-buttons {
    right: 0.625rem;
    top: 2.5rem;

  }

  .carousel-card {
    height: 17rem;
  }

  .card-img-container img {
    height: 10rem;
  }

  .card-title {
    font-size: 0.938rem;
  }

  .card-body {
    height: 40rem;
  }

  .carousel-desc {
    max-width: 100%;
    font-size: 0.8rem;
  }

  .card-arrow {
    top: 1rem;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 768px) {
  .carousel-wrapper {
    position: relative;
    max-width: 60rem;
    margin: 0 auto;
  }
  .carousel-buttons {
    display: none;

  }

  .carousel-header {
    text-align: center;
    margin-bottom: 0.625;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }


  .h1 {
    font-size: 1.4rem;
    width: 100%;

    span {
      font-size: 1.4rem;
    }
  }

  .carousel-buttons {
    top: 3.125rem;

  }

  .carousel-card {
    height: 16rem;
  }

  .card-img-container img {
    height: 6.25rem;
  }

  .card-title {
    font-size: 0.875rem;
  }

  .card-body {
    height: 33rem;
  }

  .carousel-desc {
    max-width: 100%;
    font-size: 0.7rem;
  }

  .card-arrow {
    top: 7rem ;
    right: 1rem;
    align-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}