@use '../../../../../Assets/Styles/variables';
.widget-header {
  border: none !important;
  width: 100%;
}

.widget-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dropdown-disabled {
  background-color: variables.$text-color-light !important;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .dropdown-tab-class {
    width: 110%;
  }
}