@use '../../../Assets/Styles/variables';
.mgmt-console-products-panel {

    .accordion-button {
        &:focus {
            box-shadow: none !important;
        }
    }

    .accordion-button[aria-expanded=true] {
        background: variables.$text-color-light !important;

        &:focus {
            box-shadow: none !important;
        }
    }

    .accordion {
        border: 0px;
        outline: none !important;

        .accordion-item {
            border: 0px;
            border-radius: 0.5rem;
            overflow: hidden;

            &.accordionErrors {
                border: 1px solid #ff0000;
            }

            .accordion-header {
                background: transparent;

                &:focus {
                    outline: none !important;
                    box-shadow: none !important;
                }

                .accordion-button {
                    color: variables.$primary-neutral-text-color;
                    font-weight: 500;
                    background: transparent !important;
                    padding: 0;

                    &:focus {
                        outline: none !important;
                        box-shadow: none !important;
                    }

                    background: variables.$text-color-light !important;

                    &[aria-expanded=true] {
                        background: variables.$text-color-light !important;
                        box-shadow: none;
                    }

                    .product-box {
                        .product-box-inner {
                            width: 100%;

                            img {
                                height: 1rem;
                                width: auto;
                            }
                        }
                    }
                }

            }

            .accordion-body {
                margin-top: 0px;
                padding: 0;
                width: calc(100% - 20px);
                display: flex;
                flex-wrap: wrap;

                .product-box {
                    display: flex;
                    flex-wrap: wrap;
                    width: 20%;

                    .product-box-inner {
                        width: 100%;
                        cursor: pointer;
                    }

                }
            }
        }
    }
}

.search-filter-block {
    .search-input-box {
        max-width: 25rem;
    }
}

.product-box-outer {
    box-shadow: 4px 4px 4px rgba(variables.$dropdown-disabled, 0.25);

    .product-box-name {
        height: 4rem;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 0.25rem;

        h3,
        p {
            width: 100%;
            margin-bottom: 0px;
        }
    }
}

.product-box {
    display: flex;
    flex-wrap: wrap;
    width: 97%;

    .product-box-inner {
        padding: 0.25rem;
        width: 20%;
        cursor: pointer;

        .text-muted {
            color: variables.$secondary-border-color !important;
            font-size: 0.75rem;
        }

        img {
            height: 1rem;
            width: auto;
        }

        >div {

            border: 1px solid variables.$primary-border-color;
            padding: 0.5rem;
            border-radius: 4px;
        }
    }
}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .mgmt-console-products-panel {

        .product-box {
            width: 100%;

            .product-box-inner {
                width: 50%;
            }
        }

        .accordion {

            .accordion-item {

                .accordion-header {

                    .accordion-button {
                        color: variables.$primary-neutral-text-color;
                        font-weight: 500;
                        background: transparent !important;
                        padding: 0;
                        display: flex;
                        flex-wrap: wrap;

                        &::after {
                            position: absolute;
                            right: 0;
                            top: 0.75rem;
                        }

                        .product-box {
                            width: 48%;

                            .product-box-inner {
                                width: 100%;

                                img {
                                    height: 1rem;
                                    width: auto;
                                }
                            }
                        }
                    }

                }

                .accordion-body {
                    margin-top: 0px;
                    padding: 0;
                    width: calc(100% - 20px);
                    display: flex;
                    flex-wrap: wrap;

                    .product-box {
                        display: flex;
                        flex-wrap: wrap;
                        width: 50%;

                        .product-box-inner {
                            width: 100%;
                            cursor: pointer;
                        }

                    }
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {

    .mgmt-console-products-panel {

        .product-box {
            width: 100%;

            .product-box-inner {
                width: 50%;
            }
        }

        .accordion {

            .accordion-item {

                .accordion-header {

                    .accordion-button {
                        color: variables.$primary-neutral-text-color;
                        font-weight: 500;
                        background: transparent !important;
                        padding: 0;
                        display: flex;
                        flex-wrap: wrap;

                        &::after {
                            position: absolute;
                            right: 0;
                            top: 0.75rem;
                        }

                        .product-box {
                            width: 48%;

                            .product-box-inner {
                                width: 100%;

                                img {
                                    height: 1rem;
                                    width: auto;
                                }
                            }
                        }
                    }

                }

                .accordion-body {
                    margin-top: 0px;
                    padding: 0;
                    width: calc(100% - 20px);
                    display: flex;
                    flex-wrap: wrap;

                    .product-box {
                        display: flex;
                        flex-wrap: wrap;
                        width: 50%;

                        .product-box-inner {
                            width: 100%;
                            cursor: pointer;
                        }

                    }
                }
            }
        }
    }

    .product-box {

        .product-box-inner {
            padding: 0.25rem;
            width: 50%;
        }
    }

    .download-btn {
        width: 100%;
        margin: 1rem !important;
    }

    .search-filter-block {
        padding: 0 !important;
        flex-wrap: inherit;

        .search-input-box {
            max-width: calc(100% - 4rem);
        }


        .search,
        .filter-black {
            width: auto;
            min-width: 2.5rem;
            height: 2.5rem !important;
        }
    }
}

.management-console-loading-icon {
    height: 2rem;
    width: auto;
}

.management-console-loading-text {
    color: #6AB5EA;
    font-size: 1rem;
}

.management-console-loading-component {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.management-console-no-data-customers {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: variables.$text-color-light;

}

.disabled-download-report {
    background-color: variables.$dropdown-disabled;
}