@use '../../../../../Assets/Styles/variables';
.monthly-summary-box {
  background-color: variables.$widget-background-secondary;
  margin: 0.5rem;
  border-radius: 0.75rem;

  &.disabled {
    background: #e9e9e9;
  }

  span {
    color: variables.$secondary-neutral-color !important;
  }

  width: 44.666667%;
}

.monthly-summary {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.8rem;
  margin-top: 1rem;
}

// tablet
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .monthly-summary {
    margin-top: 3rem;
  }
}

//mobile
@media only screen and (max-width: 767px) {
  .monthly-summary {
    margin-top: 3rem;
    margin-bottom: 1rem;

  }
}