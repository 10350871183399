@use '../../../../../../../../../Assets/Styles/variables';
.status-customers {
    text-align: center;
}

// for billing term(commitment term)
.gws-tooltip {
    z-index: 999999 !important;
}

.csp-vendor-id {
    font-size: 0.75rem !important;
    color: variables.$secondary-border-color;
    text-transform: uppercase;
}

.customer-table {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 7vw !important;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            >div[data-column-id="Plan"] {
                min-width: 20vw;
            }

            >div[data-column-id="Billing Term"] {
                min-width: 11vw;
            }

            >div[data-column-id="Start Date"] {
                min-width: 8vw;
            }

            >div[data-column-id="Auto Renew"] {
                min-width: 3vw;
            }

            >div[data-column-id="Status"] {
                min-width: 3vw;
            }


            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }
}

.no-data-customers {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bar-chart-height {
    height: 65vh;
}

.dropdown-disabled {
    background-color: variables.$text-color-light !important;
}

.user-management-table-inner {
    &.table-details {
        min-height: 23rem;
    }
}

@media screen and (max-width: 767px) {

    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 12vw;
                    flex: 0 0 !important;
                    padding-left: 2rem;
                    padding-right: 1rem;
                }

                >div:last-child {
                    width: auto !important;
                    min-width: 10vw;
                    flex: 0 0 !important;
                    padding-left: 1rem;
                    padding-right: 2rem;
                }
            }
        }
    }

    .bar-chart-height {
        height: 48vh;
    }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    .user-management-table-inner {
        &.table-details {
            min-height: 58rem;
        }
    }

    .search-tablet-view {
        align-items: baseline !important;
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .height-subscriptions-dashboard {
        height: 60vh;
    }

    .bar-chart-height {
        max-height: 85vh;
        scroll-behavior: hidden;
    }

    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 10vw !important;
                }


                >div[data-column-id="Quantity"] {
                    min-width: 8vw;
                }

                >div[data-column-id="Status"] {
                    min-width: 1vw;
                }

                >div[data-column-id="Billing Term"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Start Date"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Summary Date"] {
                    min-width: 15vw !important;
                }
            }
        }
    }
}

//ipad-pro
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .bar-chart-height {
        padding-top: 4rem !important;
        height: 65vh;
    }

    .height-subscriptions-dashboard {
        height: 60vh;
    }

    .search-tablet-view {
        align-items: baseline !important;
    }

    .user-management-table-inner {
        &.table-details {
            min-height: 58rem;
        }
    }

    .no-data-customers {
        min-height: 68vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .customer-table {
        div {
            &[role=row] {
                >div:first-child {
                    min-width: 20vw;
                }

                >div:last-child {
                    min-width: 10vw !important;
                }


                >div[data-column-id="Quantity"] {
                    min-width: 8vw;
                }

                >div[data-column-id="Status"] {
                    min-width: 1vw;
                }

                >div[data-column-id="Billing Term"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Start Date"] {
                    min-width: 15vw !important;
                }

                >div[data-column-id="Summary Date"] {
                    min-width: 15vw !important;
                }
            }
        }
    }
}