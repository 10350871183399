@use "../../../../src/Assets/Styles/variables.scss";
@use "../../../../src/Assets/Styles/mixins.scss";

.password-validation {

    p,
    li {
        font-size: 0.75rem;
        margin-bottom: 0px;
        color: variables.$secondary-border-color;

        span {
            font-size: 0.75rem;
            line-height: 1rem;
            color: variables.$secondary-border-color;
        }

        &.green {
            color: variables.$primary-success;

            * {
                color: variables.$primary-success;
            }
        }

        .green {
            color: variables.$primary-success;
        }

        &.red {
            color: variables.$primary-danger;

            * {
                color: variables.$primary-danger;
            }
        }

        .red {
            color: variables.$primary-danger;
        }
    }

    p {
        color: variables.$secondary-neutral-color;
    }
}


    .reset-password-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 110px);

        form {
            + div {
                height: 70px;
            }
        }
    }