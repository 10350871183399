@use './variables';
@use './mixins.scss';

.filter-box {
    min-width: 60vw;
    min-height: 10rem;
    width: auto;
    height: auto;
    max-width: calc(100% - 3rem);
    max-height: 60vh;
    overflow: hidden;
    background: variables.$text-color-light;
    border: 0.5px solid variables.$primary-border-color;
    position: absolute;
    z-index: 9999;
    right: 1.5rem;
    top: 3.5rem;
    border-radius: 0px 0px 6px 6px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 2rem;

    &.filter-box-sm {
        min-width: 30vw;
    }

    &.filter-box-xsm {
        min-width: 20vw;
    }

    &.filter-box-mid {
        width: 40vw;
    }

    &.filter-box-widget {
        min-width: 60vw;
    }

    &.filter-box-md {
        width: 70vw;
    }

    &.filter-box-lg {
        width: 80vw;
    }

    &.filter-box-xl {
        width: 100vw;
        max-width: calc(100% - 2rem);
    }

    .mobile-filter-row {
        height: auto;
        overflow: hidden;
        max-height: calc(60vh - 100px);
    }

    .filter-column {
        border-left: 1px solid variables.$primary-border-color;
        padding: 1rem;
        height: auto !important;
        overflow: auto;

        &:first-child {
            border-left: 0px !important;
            // height: 30vh !important;
            // overflow-y: auto !important;
        }

        .filter-options {
            max-height: calc(50vh - 150px);
            overflow: auto;
            padding-bottom: 1rem;
            overflow-x: hidden;

            &.date-filter-options {
                max-height: calc(50vh - 100px);
            }
        }

        .filter-options-extra-height {
            max-height: calc(50vh - 100px) !important;
        }
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .filter-box {
        position: absolute;
        top: inherit;
        left: 2rem;
        right: 2rem;
        padding: 2rem 0 2rem;
        border-radius: 0px;
        border: 0px;
        height: auto;
        max-height: calc(100vh - 20rem) !important;
        overflow: auto;
        width: calc(100vw - 4rem);
        z-index: 999;

        &.filter-box-widget {
            top: 0px !important;
        }

        &.filter-box-sm {
            min-width: 30vw;
            width: calc(50vw - 4rem);
            left: inherit;
            right: 2rem;
            top: 4.5rem;

            .mobile-filter-row {
                .filter-column {
                    width: 100%;
                    border: 0px;
                    padding: 1rem 2.8rem;
                    height: auto;
                    flex: 0 0 auto;

                }
            }
        }

        &.filter-box-xsm {
            min-width: 20vw;
            width: calc(50vw - 4rem);
            left: inherit;
            right: 2rem;
            top: 11.5rem;
            z-index: 99999;

            .mobile-filter-row {
                .filter-column {
                    width: 100%;
                    border: 0px;
                    padding: 1rem 2.8rem;
                    height: auto;
                    flex: 0 0 auto;

                }
            }
        }


        .mobile-filter-row {
            display: flex;
            max-height: calc(100% - 50px);
            overflow-y: auto;

            .filter-column {
                width: 33%;
                border: 0px;
                padding: 1rem 2.8rem;
                height: auto;
                flex: 0 0 auto;

            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .filter-box {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 2rem 0 0;
        border-radius: 0px;
        border: 0px;
        height: 100vh;
        max-height: 100vh !important;
        overflow: hidden;
        min-width: 100%;
        z-index: 99999;

        &.filter-box-sm {
            min-width: 100vw;
            top: 0;
            left: 0;
        }

        &.filter-box-xsm {
            min-width: 100vw;
            top: 0;
            left: 0;
        }

        >.container {
            padding: 0px;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 100%;
        }

        .actions-row {
            width: 100%;
            border: 1px solid variables.$primary-border-color;
            border-width: 1px 0 0;
            margin: 0px;
            padding: 1.5rem 2rem;
            flex: 0;

            >.d-flex {
                justify-content: center !important;

                button {
                    margin: 0 1rem !important;
                }
            }
        }

        .mobile-filter-row {
            display: block;
            max-height: calc(100% - 50px);
            flex: 4 2 auto;
            overflow-y: auto;

            .filter-column {
                width: 100%;
                border: 0px;
                padding: 1rem 2.8rem;
                height: auto;

                h3 {
                    font-size: 1rem;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .dashboard-filters{
        &.filter-box {
        top: 2rem !important;
        left: 2rem;
        max-height: 35vh !important;

        .mobile-filter-row {
            display: flex;
            max-height: 23vh;
            // overflow-y: auto;
            .filter-column{
                max-height: 20vh;
                overflow-y: hidden;
            }
        }
    }
}
.integration-filters{
    &.filter-box {
        top: 4rem !important;
        left: 2rem;
        max-height: 35vh !important;

        .mobile-filter-row {
            display: flex;
            max-height: 23vh;
            // overflow-y: auto;
            .filter-column{
                max-height: 20vh;
                overflow-y: hidden;
            }
        }
    }
}
}