@use "../../../../../../../Assets/Styles/variables";
.threedots:after {
    content: "\2807";
    font-size: 1rem;
}

.threeDotAnchorTag {
    text-decoration: none;
    color: variables.$actions-icon-default;
    cursor: pointer;
}

.threeDotAnchorTag:hover {
    color: variables.$actions-icon-hover;
}

.cloud-summary-table-inner {
    &.table-details {
        min-height: 33rem;
    }
}

.no-data-cloud-invoice {
    min-height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.product-plan-top-right-card {
    z-index: 999;
}

.agreement-mapping-table {
    min-height: 1rem;
}

.gws-upgrade-core {
    div {
        &[role=row] {
            >div:first-child {
                width: auto !important;
                min-width: 15vw;
                flex: 0 0 !important;
                padding-left: 2rem;
                padding-right: 1rem;
            }

            >div:last-child {
                width: auto !important;
                min-width: 9vw;
                flex: 0 0 !important;
                padding-left: 1rem;
                padding-right: 2rem;
            }

            &:hover {
                opacity: 1;
                background: variables.$table-hover-color !important;
            }
        }
    }

    div[role=table] {
        div[role=rowgroup]:first-child {
            div[data-column-id="Action"] {
                background-color: rgb(240, 240, 240) !important;

                div[role=columnheader] {
                    div {
                        background-color: transparent;
                    }
                }
            }
        }

        div[role=rowgroup]:first-child {
            div[data-column-id="Addition Description"] {
                background-color: rgb(240, 240, 240) !important;

                div[role=columnheader] {
                    div {
                        background-color: transparent;
                    }
                }
            }
        }

        div[role=rowgroup]:first-child {
            div[data-column-id="Addition Name"] {
                background-color: rgb(240, 240, 240) !important;

                div[role=columnheader] {
                    div {
                        background-color: transparent;
                    }
                }
            }
        }

        div[role=rowgroup]:first-child {
            div[data-column-id="Agreement Name"] {
                background-color: rgb(240, 240, 240) !important;

                div[role=columnheader] {
                    div {
                        background-color: transparent;
                    }
                }
            }
        }

        div[role=rowgroup]:first-child {
            div[data-column-id="Company"] {
                background-color: rgb(240, 240, 240) !important;

                div[role=columnheader] {
                    div {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}


.product-plan-top-right-card {
    height: 2.5rem;
    min-width: 13.5rem;
    width: auto;
}

@media screen and (min-width: 1200px) {
    .btn-wrapper-outer {
        padding-left: 1rem;
    }
}

@media screen and (min-width: 993px) and (max-width: 1199px) {
    .btn-wrapper-outer {
        padding-left: 0.25rem;
    }

}

@media screen and (min-width: 768px) and (max-width: 992px) {
    .btn-wrapper-outer {
        padding-left: 0.25rem;
    }

    .button-tab-view {
        font-size: 0.75rem !important;
        min-width: 98px !important;
    }

    .gws-upgrade-core {
        div {
            &[role=row] {
                >div[data-column-id="Agreement Name"] {
                    min-width: 17vw;
                }

                >div[data-column-id="Addition Description"] {
                    min-width: 20vw;
                }

                >div[data-column-id="Commitment Term (Billing Frequency)"] {
                    min-width: 28vw;
                }

                >div[data-column-id="Addition Name"] {
                    min-width: 17vw;
                }

                >div[data-column-id="Cloud Service"] {
                    min-width: 18vw;
                }
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .btn-wrapper-outer {
        padding-left: 0.25rem;

        Button {
            width: 100%;
        }
    }

    .gws-upgrade-core {
        div {
            &[role=row] {
                >div:first-child {
                    width: auto !important;
                    min-width: 25vw;
                    flex: 0 0 !important;
                    padding-left: 2rem;
                    padding-right: 1rem;
                }

                >div:last-child {
                    display: none;
                }

                >div[data-column-id="Agreement Name"] {
                    min-width: 30vw;
                }

                >div[data-column-id="Addition Description"] {
                    min-width: 30vw;
                }
                
                >div[data-column-id="Commitment Term (Billing Frequency)"] {
                    min-width: 56vw;
                }

                >div[data-column-id="Addition Name"] {
                    min-width: 25vw;
                }

                >div[data-column-id="Cloud Service"] {
                    min-width: 18vw;
                }
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .btn-wrapper-outer {
        padding-left: 1rem;
    }
}

//ipad pro 
@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .gws-upgrade-core {
        div {
            &[role=row] {
                >div[data-column-id="Commitment Term (Billing Frequency)"] {
                    min-width: 17rem;
                }   
                >div[data-column-id="Agreement Name"] {
                    min-width: 13vw;
                }
                >div[data-column-id="Addition Description"] {
                    min-width: 15vw;
                }
                >div[data-column-id="Addition Name"] {
                    min-width: 13vw;
                } 
            }
        }
    }
}