.loading-plans {
    min-height: 25vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

// for billing term(commitment term)
.gws-tooltip {
    z-index: 999999 !important;
}

.csp-change-status-core-table {

    thead tr {
        border-bottom: 0px !important;

        th {
            font-size: 0.875rem !important;
            font-weight: 500 !important;
        }
    }

    tbody tr {
        td {
            font-weight: 400 !important;
            word-break: normal !important;
            font-size: 0.875rem !important;
        }
    }

}

.modal-width {
    max-height: 70vh !important;
    overflow-y: auto;
}