@use '../../Assets/Styles/variables';
.footer-container {
  display: flex;
  flex-direction: column;
  background: linear-gradient(to right,#003661, #3ba1c2);
  color: variables.$text-color-light;
  padding: 0;
  height: auto;
  max-height: 28.125rem;
  font-size: 0.5rem;
  
}
.footer-subscribe-embedded{
}
.divContainer{
  margin-top: 1.875rem;
}
.h1-footer{
  font-size: 1.125rem;
  color: variables.$text-color-light;
  font-weight: 700;
}
.h1-footer-stay{
  font-size: 1.125rem;
  color: variables.$text-color-light;
  font-weight: 700;
  width:16.25rem;

}

// .footer-content {
//   padding: 0px 20px 10px 20px;
// }

.input-group {
  display: flex;
  align-items: center;
  border-radius: 1.25rem;
  overflow: hidden;
  background-color: variables.$text-color-light;
  width: 100%;
  max-width: 18.75rem;
  border: none;
}

.email-input {
  border-color: variables.$text-color-light;
  border-top-left-radius: 2.5rem;
  border-bottom-left-radius: 2.5rem;
  outline: none;
  border: none !important;
  
  outline-color: variables.$text-color-light;
  &:focus {
    border-color: variables.$text-color-light;
  }
}

.subscribe-btn {
  background-color: #006abc;
  border: none;
  border-radius: 1.875rem;
  height: 1.875rem;
  width: 4rem;
  color: variables.$text-color-light;
   margin: 0.188rem;
  cursor: pointer;
  font-size: 20px;
  padding-bottom: 35px;
  stroke: variables.$text-color-light;
  stroke-width: 2;
}
.chevronRight{
  transform: translateX(0.2rem);
}

.navLink {
  display: flex;
  flex-direction: column;
  height: 12.5rem;

}

.link {
    color: variables.$text-color-light !important;
    padding-bottom: 0.25rem;
    padding-top: 0.25rem;
    text-decoration: none;
    font-size: 0.875rem;
    &:hover {
      text-decoration: underline;
      color: variables.$text-color-light;
    } 
    &:active  {
      font-weight: bold;
      color: variables.$text-color-light;
      }
    &:visited  {
      color: variables.$text-color-light;
      }
}



.follow-btn-container {
  display: flex;
  justify-content: center;
  gap: 0.625rem;
  flex-wrap: wrap;
}

.follow-btn {
  background-color: #d0d5da;
  border: none;
  cursor: pointer;
  font-size: 1.125rem;
  border-radius: 50%;
  height: 1.875rem;
  width: 1.875rem;
  margin: 0.313rem;
}

.newsletter-form {
  display: flex;
  align-items: center;
  gap: 0.625rem;
}

.footer-links {
  display: flex;
  gap: 0.625rem;
  flex-wrap: nowrap;
  justify-content: flex-end; 
}
 
.footer-bottom {  
  background: linear-gradient(to right,#437aa5,#5db4db);
  div{
    margin-top: 0.5rem;
    div{
      p{
        color: variables.$text-color-light;
        text-align: left;
      }
    }
    .footer-link {
      color: variables.$text-color-light;
      text-decoration: none;
      // justify-content: flex-end;
      &:hover {
        text-decoration: underline;
      }
      &:not(:last-child)::after {
        content: '|';
        padding-left: 0.625rem;
        color: variables.$text-color-light;
      }
    }
  }
}
  
  @media screen and (max-width: 767px) {
    .footer-container {
      overflow-x: hidden;
      max-height: 100%;
    }
    .link {
      font-size: 0.9rem;
      // padding: 0 0.2rem 0.063rem 0;
      padding-left: 0;
      width: 50vw;
    }
    .navLink {
      height: auto;
      margin-bottom: 1rem;
    }
    .h1-footer:nth-child(1) {
      padding-left: 0 !important;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem !important;
    }
    .follow-btn {
      margin: 0rem 1rem 0.75rem 0rem;
    }
    .input-group {
      // max-width:9.375rem;
      margin: 0 auto;
    }
  
    .footer-bottom {
      padding:0; 
      align-items: left;
      text-align: left;
    
    }
    .h1-footer-stay{
      margin-top: 0rem;
      width:100% !important;
      display: inline-block;
    
    }
    .footer-link {
      font-size: 0.7rem;
    }
    .h1-footer-stay{
      width: 12rem;
    }
    .form-footer{
      display: inline;
      width: 50%;
      float: right;
      transform: scale(0.75);
    }
  }
  