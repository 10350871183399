@use '../../Assets/Styles/variables';
.country-code {
    width: 55px !important;
    border-radius: 0.375rem 0 0 0.375rem !important;
    border-right: 0px !important;
    display: inline-block;
}

.phone-number-wrapper {
    position: relative;

    label {
        position: absolute;
        left: 0.75rem;
        top:  0;
        line-height: 2.5rem;
        color: variables.$secondary-neutral-color !important;
    }

    .phone-number{
        padding-left: 50px;
    }
}

