@use "../../../../src/Assets/Styles/variables.scss";
@use "../../../../src/Assets/Styles/mixins.scss";

.hidden-btn {
    height: 0px !important;
    width: 0px;
    padding: 0px;
    margin: 0px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    left: -100px;
    top: -100px;
}

.pwa-install-button{
    position: absolute;
    right: 1rem;
    top: 1rem;
    display: none;
    z-index: 9999999;
}

.landing-page-alert {
    margin: auto;
    margin-top: -10px;
    position: fixed;
    top: 5px;
    left: 0;
    font-size: 12px;
    padding: 15px;
    width: 100%;
    text-align: center;
    display: none;
    opacity: 0;
    transition: all 0.5s;

    &.active {
        margin-top: 0px;
        display: block;
        opacity: 1;
        transition: all 0.5s;
        z-index: 999999;
    }

    >div {
        width: auto;
        display: inline-block;
    }
}



/* Chrome, Safari, Edge, Opera */
input.otp-number::-webkit-outer-spin-button,
input.otp-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number].otp-number {
    -moz-appearance: textfield;
}

/* Desktop Styles */
@media only screen and (min-width: 1367px) and (max-width: 1600px) {
    .landing-page-alert {
        top: 5px;
    }
}

@media only screen and (min-width: 1025px) and (max-width: 1366px) {
    .landing-page-alert {
        top: 5px;
    }
    .pwa-install-button{
        display: block;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
    .landing-page-alert {
        top: 5px;
    }
    .pwa-install-button{
        display: block;
    }
}

/* Tablet Styles */
@media only screen and (min-width: 768px) and (max-width: 991px) {
    .landing-page-alert {
        top: 10px;
    }
    .pwa-install-button{
        display: block;
    }
}

/* Mobile Styles */
@media only screen and (max-width: 767px) {
    .landing-page-alert {
        top: 10px;
    }
    .pwa-install-button{
        display: block;
    }
}