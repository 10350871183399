@use '../../../../../../../Assets/Styles/variables';
.monthly-summary-box {
  background-color: variables.$widget-background-secondary;
  margin: 0.5rem;
  border-radius: 0.75rem;

  span {
    color: variables.$secondary-neutral-color !important;
  }
}

.monthly-summary {
  border-radius: 0.75rem;
  background-color: variables.$text-color-light;
  padding: 0.5rem 0.8rem;
}