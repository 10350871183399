
@use '../../../../../Assets/Styles/variables';
.border-header {
    border-bottom: 1px solid #E5E7EB;
  }

  // Dropdown widths
  .border-width-config {
    &-date {
      width: 8%;
    }

    &-month {
      width: 11%;
    }
  }

  // Content area styles
  .content {
  .notificationSettings {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    width: 100%;
    
    .notification-group {
      display: flex;
      align-items: center;
      gap: 8px;
      white-space: nowrap;
      
      span {
        font-size: 14px;
        white-space: nowrap;
      }
    }
    
    // Dropdown widths for desktop and tablet
    .border-width-config {
      &-date {
        width: 100px;
        min-width: 100px;
      }
      
      &-month {
        width: 120px;
        min-width: 120px;
      }
    }
  }

    .reportNote {
      font-size: 14px;
      margin-bottom: 24px;

      span {
        font-size: 14px;
        font-weight: 700;
      }
    }

    @media screen and (min-width: 821px) {
    .notes-confirmation-configure-notification {
      transform: translateY(22%);
    }
  }

    .note {
      font-size: 12px;

      span {
        font-size: 12px;
        font-weight: 700;
      }
    }
  }

  // Media queries
  @media (max-width: 767px) {
    .border-width-config {
      &-date,
      &-month {
        width: 63% !important;
      }
    }

    .viewtemplate-header {
      text-align: start;
    }

    .downloadreport-header {
      padding: 0;
    }

     .content {
    .notificationSettings {
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      
      .notification-group {
        display: flex;
        align-items: center;
        gap: 8px;
        // margin-bottom: 8px;
        flex: 1;
        // min-width: 200px;
        
        span {
          font-size: 12px;
          white-space: nowrap;
        }
      }
    }

      .reportNote,
      .note {
        text-align: left;
        font-size: 12px;

        span {
        font-size: 12px;
        font-weight: 700;
      }
      }

      .note {
        padding: 0;
        margin: 0 0 16px 11px;
        width: 100%;
      }
    }

    .configure-notification-button {
      width: 100%;
      margin: 4px 0;
    }
  }

  @media only screen and (min-width: 768px) and (max-width: 820px) {

    .downloadreport-header {
      padding: 0;
      text-align: right;
    }
}


   .email-member-options {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 0;
    margin-top: 5px;

    label {
      align-items: center;
      background: hsla(0, 0%, 87%, 0.45);
      border-radius: 6px;
      color: variables.$secondary-neutral-color;
      display: flex;
      margin: 0 5px 5px 0;
      padding: 8px 0 8px 10px;
      width: calc(100% - 5px);

      span {
        color: variables.$secondary-neutral-color;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
      }

      .close-icon-popup-sm {
        margin-left: auto;
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }