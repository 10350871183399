@use '../../../../Assets/Styles/variables';
.add-user-service-dropdown{
    font-weight: 500;
    color: variables.$secondary-neutral-color;
}

.no-role {
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.role-select-dropdown {
    width: 100%;
}

.profile-table {
    padding-left: 2rem;
    padding-right: 2rem;
}

.my-profile-section {
    color: variables.$secondary-neutral-color;
    font-weight: 500;
}