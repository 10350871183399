@use '../../../../../../../Assets/Styles/variables';
// Details Box
.success-card{
    background-color: variables.$primary-success-hover !important ;
    color: variables.$primary-success !important;
}
.error-card{
    background-color: variables.$primary-danger-hover !important;
    color: variables.$primary-danger !important;
}
.loading-customers {
    min-height: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}