@use '../../Assets/Styles/variables';
.announcement-body-main{
    max-height: 75vh;
    min-width: 22rem;
    padding: 1rem;
    max-width: 23rem;
    overflow-x: auto;
    // min-height: 45vh;
}
.announcements-body{
    max-height: 58vh;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}
.announcement-centre-description{
    // overflow: hidden;

    p {
        white-space: normal;
    }
}
.announcement-created-at{
    color: variables.$secondary-neutral-color;
}
.carosel-announcement{
    display: block !important;
}

.caroseul-announcement {
    padding-left: 30px;
    padding-right: 30px;
  }
  
  .carousel-control-prev {
    margin-left: -15px;
  }
  
  .carousel-control-next {
    margin-right: -15px;
  }
  .text-only-announcement{
    height: 11rem;
    width: 100%;
    white-space: initial;
    word-break: keep-all;
    overflow-y: auto;
    text-align:justify !important ;
  }
  .payment-gateway-others-numbers{
    width: 1.3rem; 
    line-height: 1.2rem; 
    border-radius: 50%; 
    text-align: center; 
    border: 1px variables.$primary-color solid;
    background-color: transparent; 
    color: variables.$primary-color; 
    font-weight: 500;
    font-size: 0.75rem;
  }