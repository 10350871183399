@use '../../Assets/Styles/variables';
.feedback-drawer-outer{
    z-index: 99999 !important;
}
.feedback-drawer-main{
    width: 50vw !important;
    height:100vh;
    overflow-y: scroll;

}
.feedback-drawer-query-main{
    margin-top: 1rem;
    margin-bottom: 1rem;
    max-height: 80vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}
.rating-main-div{
    .MuiBox-root{
        padding-left: 3rem;
    }
}
.MuiRating-root {
    transform: scale(2);
    // .MuiRating-decimal{
    //     label *{
            
    //     }
        
    // }
}

.MuiRating-icon{
    color: variables.$feedback-star-color ;
    svg{
        path{
             color: variables.$feedback-star-color ;
        }
       
    }
}
.MuiRating-iconEmpty{
    
    svg{
        path{fill: none;
            stroke: #828282;
            stroke-width: 1px;
            // stroke-dasharray: 2,2;
            stroke-linejoin: round;
        }
       
    }
}
.right-overlay-btn{
    .btn.btn-primary, .popup-content .btn.btn-primary {
        background: variables.$primary-color;
        border-color: variables.$primary-color;
    }
    
    .btn, .popup-content .btn {
        height: 2.5rem;
        border-radius: 3rem;
        line-height: 1;
        font-size: 0.875rem;
        font-weight: 500;
        min-width: 100px;
        padding: 8px 10px;
    }

    .btn-border-light{
        background: variables.$text-color-light;
        border: 1px solid variables.$primary-color;
        color: variables.$primary-color;
    }
    .btn:disabled, .btn.btn-disabled {
        background-color: variables.$primary-border-color;
        border-color: variables.$primary-border-color;
        color: variables.$secondary-border-color;
    }
}
.feedback-drawer-contact-heading{
    font-size: 1rem;
}
.feedback-height{
    height: calc(100% - 7rem);
    overflow-y: auto;
}
.feedback-buttons{
    max-height: 10vh;
}

@media screen and (max-width: 767px) {
    .feedback-drawer-main{
        width: 100vw !important;
        height:100vh;
        overflow-y: scroll;
    
    }
}