@use "../../../../../../Assets/Styles/variables";
.table-heading {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}
.expandable-table-iaas-usage-report{
    padding-left: 4vw;
    padding-right: 30vw;
}
.loading-unbilled-usage {
    min-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

div {
    &[role=columnheader] {
        >div {
            color: variables.$secondary-neutral-color;
            font-size: 0.875rem;
            font-weight: 500;
            display: flex;
            align-items: center;
        }

        &:hover {
            opacity: 1;

            >div {
                color: variables.$secondary-neutral-color;
                font-size: 0.875rem;
                font-weight: 500;


            }
        }
    }
}

div {
    &[role=cell] {
        div {
            font-size: 0.875rem;
            line-height: 1.125rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;

            div {
                color: variables.$primary-neutral-text-color;
                font-size: 0.875rem;
            }
        }
    }
}

.unbilled-usage-table-inner{div {
    &[role=row] {

        >div:first-child{
            width: auto !important;
            min-width: 6vw;
            flex: 0 0 !important;
            padding-left: 0rem;
            padding-right: 0rem;
        }
        >div:last-child {
            width: auto !important;
            min-width: 9vw;
            flex: 0 0 !important;
            padding-left: 2rem;
            padding-right: 2rem;
        }

        &:hover {
            opacity: 1;
            background: variables.$table-hover-color !important;
        }
    }
}
}